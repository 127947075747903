
// External Imports
import { computed, defineComponent, ref, toRefs, watch } from 'vue';
import dayjs from 'dayjs';
import bytes from 'bytes';
// Project Imports
import useTranslate from '@/hooks/useTranslate';
import { MtButton, MtClearButton, MtDeleteButton } from '@/components/MtButton';
import MtBottomCard from '@/components/MtBottomCard';
import { useEmitter } from '@/utils/useEmitter';
import useAppConfig from '@/hooks/useAppConfig';
import useMessageService from '@/hooks/useMessageService';
import useCurrentTimeMsAtIntervalMs from '@/hooks/useCurrentTimeMsAtIntervalMs';
import useFileManagerService from '@/hooks/useFileManagerService';
import * as ExportItem from '../classes/ExportItem';
import { MtFileManagerTableProps, MtFileManagerTableEvents, TableItem } from './MtFileManagerTable';
import { MtConfirmDeleteOverlay } from './MtConfirmDeleteOverlay';

export default defineComponent({
  components: { MtButton, MtClearButton, MtDeleteButton, MtConfirmDeleteOverlay, MtBottomCard },
  props: {
    loading: {
      type: Boolean,
    },
    tableItems: {
      type: Array,
    },
    tableItemLinkExpiresInMs: {
      type: Number,
    },
    deletingExports: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: MtFileManagerTableProps) {
    const appConfig = useAppConfig();
    const t = useTranslate();
    const fileManagerService = useFileManagerService();
    const messageService = useMessageService();
    const emit = useEmitter<MtFileManagerTableEvents>();
    const currentTimeMs = useCurrentTimeMsAtIntervalMs(10000);

    const { deletingExports, tableItems, tableItemLinkExpiresInMs } = toRefs(props);

    // Headers are computed to make sure they react to translation changes
    const tableHeaders = computed(() => [
      // Available header options: https://vuetifyjs.com/en/api/v-data-table/#props-headers
      { text: t('mt.views.file-manager.export-list.date'), value: 'date' },
      { text: t('mt.views.file-manager.export-list.filesize'), value: 'filesize' },
      { text: t('mt.views.file-manager.export-list.filename'), value: 'filename' },
      { text: t('mt.views.file-manager.export-list.status'), value: 'status' },
    ]);
    // Used to keep track if specific downloads are currently being requested.
    const downloadsLoading = ref<Set<string>>(new Set());
    const showConfirmDelete = ref<boolean>(false);
    const selectedExports = ref<TableItem[]>([]);

    // Will close the modal when the delete is complete.
    watch(deletingExports, (newValue) => {
      if (newValue === false) {
        showConfirmDelete.value = false;
      }
    });

    // If any query ids are removed from the list we want to remove them from the selectedExports as well.
    watch(tableItems, (newValue) => {
      const newQueryIdsList = newValue.map(({ queryId }) => queryId);
      const availableQueryIds = new Set(newQueryIdsList);
      selectedExports.value = selectedExports.value.filter(({ queryId }) => availableQueryIds.has(queryId));
    });

    const isTableItemLinkExpirationEnabled = computed<boolean>(() => !!tableItemLinkExpiresInMs?.value);

    const tableItemLinkExpiresInHours = computed<number>(() =>
      dayjs.duration(tableItemLinkExpiresInMs?.value || 0).asHours()
    );

    const isFileExpired = computed(
      () =>
        (item: ExportItem.ExportItem): boolean =>
          ExportItem.isExpired(item, tableItemLinkExpiresInMs?.value, currentTimeMs.value)
    );

    const isFileDownloadable = computed(
      () =>
        (item: ExportItem.ExportItem): boolean =>
          ExportItem.isDownloadable(item, tableItemLinkExpiresInMs?.value, currentTimeMs.value) &&
          !downloadsLoading.value.has(item.queryId)
    );

    function formatFilesize(sizeBytes: number): string {
      return bytes.format(sizeBytes, { unitSeparator: ' ' });
    }

    function formatDate(ts: string) {
      return dayjs(ts).tz(appConfig.timezone).format('ll LT');
    }

    async function downloadExport(queryId: string) {
      downloadsLoading.value = new Set(downloadsLoading.value).add(queryId);
      try {
        const exportDownload = await fileManagerService.getExportDownload(queryId);
        window.location.href = exportDownload.url;
      } catch (e) {
        messageService.queueMessage({
          type: 'error',
          text: 'mt.views.file-manager.export-list.download-export-failed',
          secondaryText: 'mt.views.file-manager.unexpected-error',
        });
      }
      const newDownloadsLoading = new Set(downloadsLoading.value);
      newDownloadsLoading.delete(queryId);
      downloadsLoading.value = newDownloadsLoading;
    }

    function clearSelection() {
      selectedExports.value = [];
    }

    function openDeleteModal() {
      showConfirmDelete.value = true;
    }

    function closeDeleteModal() {
      showConfirmDelete.value = false;
    }

    async function deleteSelectedExports() {
      const queryIds = selectedExports.value.map((selectedExport) => selectedExport.queryId);
      emit('deleteExports', { queryIds });
    }

    return {
      clearSelection,
      closeDeleteModal,
      deleteSelectedExports,
      downloadExport,
      downloadsLoading,
      formatDate,
      formatFilesize,
      isFileDownloadable,
      isFileExpired,
      isTableItemLinkExpirationEnabled,
      openDeleteModal,
      selectedExports,
      showConfirmDelete,
      t,
      tableHeaders,
      tableItemLinkExpiresInHours,
    };
  },
});
