import { NavigationGuard } from 'vue-router';
import { RouterHookConfig } from '@/router/index';
import { MessageService } from '@/components/MtMessages/MessageService';

export type Config = {
  messageService: MessageService;
};

/*
 * TODO: We may want some messages to be persisted across navigation (e.g. messages produced by guards)
 *   2021-03-25 by: @maragon
 */
export function clearMessagesGuard(config: Config): RouterHookConfig {
  const guard: NavigationGuard = async (to, from, next) => {
    config.messageService.clearMessages();
    next();
  };

  return {
    stage: 'beforeEach',
    hook: guard,
  };
}
