import { ref, Ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';

function useCurrentTimeMsAtIntervalMs(intervalMs: number | Ref<number>): Ref<number> {
  const utcTime = ref(Date.now());

  useIntervalFn(() => {
    utcTime.value = Date.now();
  }, intervalMs);

  return utcTime;
}

export default useCurrentTimeMsAtIntervalMs;
