import { PromiseRejectionEventHandler } from '@/services/errors/PromiseRejectionEventHandler';
import { AuthService } from '@/services/auth/AuthService';

export type Config = {
  authService: AuthService;
};

export class AuthTokenExpiredErrorHandler implements PromiseRejectionEventHandler<Response> {
  protected readonly _authService: AuthService;

  constructor(config: Config) {
    this._authService = config.authService;
  }

  canHandle(event: PromiseRejectionEvent, reason: unknown): reason is Response {
    // When using serverless-offline, there's a known issue where authorizers will always fail with 403 (instead of 401)
    // See: https://github.com/dherault/serverless-offline/issues/710
    // This is not a problem (for now), but should be fixed to allow proper error reporting to users
    // (e.g. when a user is trying to do something they don't have permission to)
    if (process.env.VUE_APP_API_OFFLINE) {
      return reason instanceof Response && reason.status === 403;
    }

    return reason instanceof Response && reason.status === 401;
  }

  handle(): void {
    // noinspection JSIgnoredPromiseFromCall
    this._authService.logout();
  }
}
