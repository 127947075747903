import { KymAppReportService } from '@/services/kym/KymAppReportService';
import { KymAppReport } from '@/services/kym/KymAppReport';
import { ApiService } from '@/services/ApiService';

export class ApiKymAppReportService extends ApiService implements KymAppReportService {
  async get(): Promise<KymAppReport | null> {
    const response = await this.fetch(`${this._API_BASE_URL}/dataSnapshot/getCurrentSnapshot`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
      },
    });

    switch (response.status) {
      case 200: {
        return response.json();
      }
      case 404: {
        return null;
      }
      default:
        throw response;
    }
  }
}
