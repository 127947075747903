import { provide, inject, InjectionKey } from 'vue';
import { unit, FileManagerService } from '@/services/FileManagerService';

const injectionKey = Symbol('fileManager') as InjectionKey<FileManagerService>;

export function provideFileManagerService(config: FileManagerService): void {
  return provide(injectionKey, config);
}

/**
 * Composition function that returns Config
 */
function useFileManagerService(): FileManagerService {
  return inject(injectionKey, unit);
}

export default useFileManagerService;
