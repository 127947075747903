var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-8", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-sheet",
            { staticClass: "col-12", attrs: { color: "transparent" } },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("mt.views.kym.filters"))),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  label: _vm.$t("mt.models.account-types._"),
                                  placeholder: _vm.$t("mt.views.kym.show-all"),
                                  multiple: "",
                                  chips: "",
                                  "small-chips": "",
                                  "deletable-chips": "",
                                  clearable: "",
                                  "item-value": "id",
                                  "item-text": "name",
                                  items: _vm.accountTypes$,
                                },
                                model: {
                                  value: _vm.selectedAccountTypes$,
                                  callback: function ($$v) {
                                    _vm.selectedAccountTypes$ = $$v
                                  },
                                  expression: "selectedAccountTypes$",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  label: _vm.$t("mt.models.institutions"),
                                  placeholder: _vm.$t("mt.views.kym.show-all"),
                                  multiple: "",
                                  chips: "",
                                  "small-chips": "",
                                  "deletable-chips": "",
                                  clearable: "",
                                  "item-value": "id",
                                  "item-text": "name",
                                  items: _vm.institutions$,
                                },
                                model: {
                                  value: _vm.selectedInstitutions$,
                                  callback: function ($$v) {
                                    _vm.selectedInstitutions$ = $$v
                                  },
                                  expression: "selectedInstitutions$",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-sheet",
            { staticClass: "col-12 col-xl-6", attrs: { color: "transparent" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center text-h5",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.t("mt.views.kym.sheets.titles.customers"))
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("mt-graph-card", {
                        attrs: {
                          title: "mt.views.kym.by-account-types",
                          data: _vm.guestsByAccountTypes$,
                          "filtered-labels": [],
                          "label-title": "mt.views.kym.tables.account-type",
                          "value-title": "mt.views.kym.tables.customers",
                          "percentage-title": "mt.views.kym.tables.percentage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("mt-graph-card", {
                        attrs: {
                          title: "mt.views.kym.by-financial-institutions",
                          data: _vm.guestsByInstitutions$,
                          "filtered-labels": [],
                          "label-title": "mt.views.kym.tables.institution",
                          "value-title": "mt.views.kym.tables.customers",
                          "percentage-title": "mt.views.kym.tables.percentage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-sheet",
            { staticClass: "col-12 col-xl-6", attrs: { color: "transparent" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center text-h5",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.t("mt.views.kym.sheets.titles.accounts"))
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("mt-graph-card", {
                        attrs: {
                          title: "mt.views.kym.by-account-types",
                          data: _vm.accountsByAccountTypes$,
                          "filtered-labels": [],
                          "label-title": "mt.views.kym.tables.account-type",
                          "value-title": "mt.views.kym.tables.accounts",
                          "percentage-title": "mt.views.kym.tables.percentage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("mt-graph-card", {
                        attrs: {
                          title: "mt.views.kym.by-financial-institutions",
                          data: _vm.accountsByInstitutions$,
                          "filtered-labels": [],
                          "label-title": "mt.views.kym.tables.institution",
                          "value-title": "mt.views.kym.tables.accounts",
                          "percentage-title": "mt.views.kym.tables.percentage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-sheet",
            { staticClass: "col-12 col-xl-6", attrs: { color: "transparent" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center text-h5",
                      attrs: { cols: "12" },
                    },
                    [_vm._v(_vm._s(_vm.t("mt.views.kym.sheets.titles.assets")))]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("mt-graph-card", {
                        attrs: {
                          title: "mt.views.kym.by-account-types",
                          data: _vm.assetsByAccountTypes$,
                          "value-formatter": _vm.currencyFormatter,
                          "filtered-labels": [],
                          "label-title": "mt.views.kym.tables.account-type",
                          "value-title": "mt.views.kym.tables.balances",
                          "percentage-title": "mt.views.kym.tables.percentage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("mt-graph-card", {
                        attrs: {
                          title: "mt.views.kym.by-financial-institutions",
                          data: _vm.assetsByInstitutions$,
                          "value-formatter": _vm.currencyFormatter,
                          "filtered-labels": [],
                          "label-title": "mt.views.kym.tables.institution",
                          "value-title": "mt.views.kym.tables.balances",
                          "percentage-title": "mt.views.kym.tables.percentage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-sheet",
            { staticClass: "col-12 col-xl-6", attrs: { color: "transparent" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center text-h5",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.t("mt.views.kym.sheets.titles.liabilities"))
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("mt-graph-card", {
                        attrs: {
                          title: "mt.views.kym.by-account-types",
                          data: _vm.liabilitiesByAccountTypes$,
                          "value-formatter": _vm.currencyFormatter,
                          ascending: "",
                          "filtered-labels": [],
                          "label-title": "mt.views.kym.tables.account-type",
                          "value-title": "mt.views.kym.tables.balances",
                          "percentage-title": "mt.views.kym.tables.percentage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("mt-graph-card", {
                        attrs: {
                          title: "mt.views.kym.by-financial-institutions",
                          data: _vm.liabilitiesByInstitutions$,
                          "value-formatter": _vm.currencyFormatter,
                          ascending: "",
                          "filtered-labels": [],
                          "label-title": "mt.views.kym.tables.institution",
                          "value-title": "mt.views.kym.tables.balances",
                          "percentage-title": "mt.views.kym.tables.percentage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-sheet",
            { staticClass: "col-12 col-xl-6", attrs: { color: "transparent" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center text-h5",
                      attrs: { cols: "12" },
                    },
                    [_vm._v(_vm._s(_vm.t("mt.views.kym.sheets.titles.points")))]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("mt-graph-card", {
                        attrs: {
                          title: "mt.views.kym.by-financial-institutions",
                          data: _vm.pointsByInstitutions$,
                          "value-formatter": _vm.pointsFormatter,
                          "filtered-labels": [],
                          "label-title": "mt.views.kym.tables.institution",
                          "value-title": "mt.views.kym.tables.balances",
                          "percentage-title": "mt.views.kym.tables.percentage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }