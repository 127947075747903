export type RequestConfigurer = (input: RequestInfo, init: RequestInit) => Promise<RequestInit>;

export const identity: RequestConfigurer = (_, i) => Promise.resolve(i);

export const pipe = (configurers: ReadonlyArray<RequestConfigurer>): RequestConfigurer => {
  return async (input, init) => {
    let acc = init;
    for (const configure of configurers) {
      acc = await configure(input, acc);
    }
    return acc;
  };
};
