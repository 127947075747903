import { ref, Ref } from 'vue';
import { AsyncState } from '@/models/AsyncState';

type PromiseFn = (...args: any[]) => Promise<any>;
type PromiseFnReturnType<A extends PromiseFn> = A extends Promise<infer B> ? B : never;

export type AsyncRequest<A extends PromiseFn> = {
  run: (...x: Parameters<A>) => Promise<void>;
  state: Ref<AsyncState<PromiseFnReturnType<A>>>;
};

function useAsyncRequest<A extends PromiseFn>(fn: A): AsyncRequest<A> {
  const state = ref({ status: 'idle' }) as Ref<AsyncState<PromiseFnReturnType<A>>>;
  return {
    state,
    run: async (...x: Parameters<A>) => {
      state.value = { status: 'loading' };
      try {
        const result = await fn(...x);
        state.value = { status: 'success', result };
      } catch (error) {
        state.value = {
          status: 'failure',
          result: error instanceof Error ? error : new Error('Request failed'),
        };
      }
    },
  };
}

export default useAsyncRequest;
