var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "mt-file-manager-export-list", attrs: { fluid: "" } },
    [
      _c("mt-confirm-delete-overlay", {
        attrs: {
          show: _vm.showConfirmDelete,
          loading: _vm.deletingExports,
          noSelected: _vm.selectedExports.length,
        },
        on: {
          confirm: function ($event) {
            return _vm.deleteSelectedExports()
          },
          cancel: function ($event) {
            return _vm.closeDeleteModal()
          },
        },
      }),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("h2", { staticClass: "mt-file-manager-export-list__header" }, [
            _vm._v(
              _vm._s(_vm.t("mt.views.file-manager.export-list.export-list"))
            ),
          ]),
          _vm.isTableItemLinkExpirationEnabled
            ? _c("v-col", { attrs: { "align-self": "end" } }, [
                _c("p", { staticClass: "text-right text-subtitle-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "mt.views.file-manager.export-list.export-expiration-info",
                          { hours: _vm.tableItemLinkExpiresInHours }
                        )
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-data-table",
            {
              staticClass: "mt-file-manager-export-list__export-table",
              attrs: {
                loading: _vm.loading,
                headers: _vm.tableHeaders,
                items: _vm.tableItems,
                "disable-pagination": "",
                "hide-default-footer": "",
                "sort-by": "date",
                "sort-desc": "",
                "must-sort": "",
                "item-key": "queryId",
                "selectable-key": "deleteable",
                "show-select": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.date`,
                    fn: function ({ item }) {
                      return [
                        _vm._v(" " + _vm._s(_vm.formatDate(item.date)) + " "),
                      ]
                    },
                  },
                  {
                    key: `item.filesize`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            class: {
                              "text--disabled": _vm.isFileExpired(item),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatFilesize(item.filesize)) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.filename`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "mt-button",
                          {
                            staticClass:
                              "mt-file-manager-export-list__download-button pa-0",
                            attrs: {
                              color: "link",
                              text: "",
                              disabled: !_vm.isFileDownloadable(item),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadExport(item.queryId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(/* Only disable and show a spinner for the specific item being requested. */) +
                                " "
                            ),
                            _vm.downloadsLoading.has(item.queryId)
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    size: 16,
                                    width: 3,
                                    indeterminate: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(item.filename) + " "),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.status`,
                    fn: function ({ item }) {
                      return [
                        _c("div", [
                          _vm.isFileExpired(item)
                            ? _c("div", { staticClass: "text--disabled" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.t(
                                        `mt.views.file-manager.export-list.status-expired`
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.t(
                                        `mt.views.file-manager.export-list.status-${item.status}`
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.selectedExports,
                callback: function ($$v) {
                  _vm.selectedExports = $$v
                },
                expression: "selectedExports",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(/* These templates are only needed for columns with special logic */) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "mt-bottom-card",
        {
          staticClass: "mt-file-manager-export-list__bottom_card",
          attrs: { show: _vm.selectedExports.length > 0 },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "flex-nowrap", attrs: { align: "center" } },
                [
                  _c("v-col", [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "mt.views.file-manager.export-list.items-selected",
                              { noSelected: _vm.selectedExports.length }
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { sm: "auto" } },
                    [
                      _c("mt-clear-button", {
                        on: {
                          click: function ($event) {
                            return _vm.clearSelection()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "auto" } },
                    [
                      _c("mt-delete-button", {
                        on: {
                          click: function ($event) {
                            return _vm.openDeleteModal()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }