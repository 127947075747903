var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-img", {
    attrs: {
      src: `${_vm.publicPath}assets/moneytree-logo-black.svg`,
      contain: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }