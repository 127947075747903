export enum Entity {
  Guests = 'guests',
  Accounts = 'accounts',
  Transactions = 'transactions',
}

export enum Location {
  FileManager = 'FileManager',
}

export enum FormatType {
  CSV = 'CSV',
}

export interface Field {
  name: string;
  as: string;
}

export interface SpecialConstant {
  type: string;
  value: string | number;
}

export interface Condition {
  fieldName: string;
  in?: Array<SpecialConstant | string | number>;
  notIn?: Array<SpecialConstant | string | number>;
  between?: Array<SpecialConstant | string | number>;
}

export interface Transform {
  fieldNames: string[];
  map?: Record<string, string>;
  condition?: Condition;
}

export interface ExecutePreparedQueryRequestOptions {
  id: string;
  queryId?: string;
  queryParams?: Record<string, string>;
}

export type ExportStatus = 'QUEUED' | 'INPROGRESS' | 'DONE' | 'FAILED';

export interface ExportMetadata {
  queryId: string;
  request: {
    entity: Entity;
    query: {
      fields: Array<Field>;
      conditions?: Array<Condition>;
      transforms?: Array<Transform>;
    };
    output: {
      location: Location;
      filename?: string;
      format: {
        type: FormatType;
      };
    };
  };
  requestedAt: number;
  requestedBy?: string | null;
  status: ExportStatus;
  systemFilename?: string | null;
  userFilename?: string | null;
  filesize?: number | null;
}

export type ExecutePreparedQueryResponse = ExportMetadata;

export interface GetExportQueriesResponse {
  queryIds: string[];
  fileDownloadExpiresInMs?: number | null;
}

export type GetExportMetadataResponse = ExportMetadata;

export interface GetExportDownloadResponse {
  url: string;
}

export interface DeleteExportsResponse {
  deleted: string[];
  missing: string[];
}

export interface FileManagerService {
  executePreparedQuery(options: ExecutePreparedQueryRequestOptions): Promise<ExecutePreparedQueryResponse>;
  getExportQueries(): Promise<GetExportQueriesResponse>;
  getExportMetadata(queryId: string): Promise<GetExportMetadataResponse>;
  getExportDownload(queryId: string): Promise<GetExportDownloadResponse>;
  deleteExports(queryIds: string[]): Promise<DeleteExportsResponse>;
}

export const unit: FileManagerService = {
  executePreparedQuery: () =>
    Promise.resolve({
      request: {
        entity: Entity.Guests,
        query: {
          fields: [],
        },
        output: {
          location: Location.FileManager,
          format: {
            type: FormatType.CSV,
          },
        },
      },
      queryId: '',
      requestedAt: 0,
      status: 'INPROGRESS',
    }),
  getExportQueries: () =>
    Promise.resolve({
      queryIds: [],
      fileDownloadExpiresInMs: null,
    }),
  getExportMetadata: () =>
    Promise.resolve({
      request: {
        entity: Entity.Guests,
        query: {
          fields: [],
        },
        output: {
          location: Location.FileManager,
          format: {
            type: FormatType.CSV,
          },
        },
      },
      queryId: '',
      requestedAt: 0,
      status: 'INPROGRESS',
    }),
  getExportDownload: () =>
    Promise.resolve({
      url: '',
    }),
  deleteExports: () =>
    Promise.resolve({
      deleted: [],
      missing: [],
    }),
};
