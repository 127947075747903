import {
  ExecutePreparedQueryRequestOptions,
  ExecutePreparedQueryResponse,
  GetExportQueriesResponse,
  GetExportMetadataResponse,
  GetExportDownloadResponse,
  FileManagerService,
  DeleteExportsResponse,
} from '@/services/FileManagerService/index';
import { ApiService } from '@/services/ApiService';

export class ApiFileManagerService extends ApiService implements FileManagerService {
  async executePreparedQuery(options: ExecutePreparedQueryRequestOptions): Promise<ExecutePreparedQueryResponse> {
    const response = await this.fetch(`${this._API_BASE_URL}/query/executePreparedQuery`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...options,
      }),
    });

    if (!response.ok) {
      const err = await response.json().catch(() => response);
      throw err;
    }

    return response.json() as Promise<ExecutePreparedQueryResponse>;
  }

  async getExportQueries(): Promise<GetExportQueriesResponse> {
    const response = await this.fetch(`${this._API_BASE_URL}/filemanager/queries`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      const err = await response.json().catch(() => response);
      throw err;
    }

    return response.json() as Promise<GetExportQueriesResponse>;
  }

  async getExportMetadata(queryId: string): Promise<GetExportMetadataResponse> {
    const response = await this.fetch(`${this._API_BASE_URL}/filemanager/metadata?queryId=${queryId}`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      const err = await response.json().catch(() => response);
      throw err;
    }

    return response.json() as Promise<GetExportMetadataResponse>;
  }

  async getExportDownload(queryId: string): Promise<GetExportDownloadResponse> {
    const response = await this.fetch(`${this._API_BASE_URL}/filemanager/download`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        queryId,
      }),
    });

    if (!response.ok) {
      const err = await response.json().catch(() => response);
      throw err;
    }

    return response.json() as Promise<GetExportDownloadResponse>;
  }

  async deleteExports(queryIds: string[]): Promise<DeleteExportsResponse> {
    const response = await this.fetch(`${this._API_BASE_URL}/filemanager/delete`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        queryIds,
      }),
    });

    if (!response.ok) {
      const err = await response.json().catch(() => response);
      throw err;
    }

    return response.json() as Promise<DeleteExportsResponse>;
  }
}
