import merge from 'lodash.merge';
import { RequestConfigurer } from '@/services/fetch/RequestConfigurer';
import { AuthService } from '@/services/auth/AuthService';

export type Config = {
  authService: AuthService;
};

export const tokenAuthenticationConfigurer = ({ authService }: Config): RequestConfigurer => {
  const localUrl = new URL('', window.location.href);

  return async (input, init) => {
    if (typeof input !== 'string') {
      input = input.url;
    }
    const targetUrl = new URL(input, window.location.href);
    if (targetUrl.origin !== localUrl.origin) {
      // External URL so we do not add authorization details
      return init;
    }

    let token;

    try {
      token = await authService.getToken();
    } catch (error) {
      authService.initiateAuthentication();
      throw error;
    }

    return merge(
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } as RequestInit,
      init
    );
  };
};
