import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';
import { Props } from '@/types/vue';

export interface MtOverlayData {}

export interface MtOverlayMethods {}

export interface MtOverlayComputed {}

export interface MtOverlayProps extends Props {
  show: boolean;
}

export type MtOverlayEvents = {
  'click:outside': Record<string, never>;
};

export type MtOverlayModalInstance = CombinedVueInstance<
  Vue,
  MtOverlayData,
  MtOverlayMethods,
  MtOverlayComputed,
  MtOverlayProps,
  void
>;
