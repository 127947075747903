import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ErrorView from '@/views/ErrorView.vue';
import KymView from '@/views/kym/KymView.vue';
import FileManagerView from '@/views/fileManager/FileManagerView.vue';
import { RouterHookConfig } from '@/router';
import MtDashboard from '@/components/MtDashboard/MtDashboard.vue';

export type Config = {
  routerHooks: RouterHookConfig[];
};

export function configureRouter({ routerHooks }: Config): VueRouter {
  Vue.use(VueRouter);

  const routes: Array<RouteConfig> = [
    {
      path: '/error',
      name: 'Error',
      component: ErrorView,
    },
    {
      path: '/dashboard',
      component: MtDashboard,
      children: [
        {
          path: '',
          redirect: { name: 'File Manager' },
        },
        {
          path: 'file-manager',
          name: 'File Manager',
          component: FileManagerView,
        },
        {
          path: 'know-your-market',
          name: 'Kym',
          component: KymView,
        },
      ],
    },
    {
      path: '',
      redirect: { name: 'File Manager' },
    },
    {
      path: '/*',
      name: '404',
      component: ErrorView,
    },
  ];

  const router = new VueRouter({
    routes,
  });

  routerHooks.forEach((config) => {
    switch (config.stage) {
      case 'beforeEach':
      case 'beforeResolve': {
        router.beforeEach(config.hook);
        break;
      }
      case 'afterEach': {
        router[config.stage](config.hook);
        break;
      }
    }
  });

  return router;
}
