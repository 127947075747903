var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "mt-overlay",
    { staticClass: "mt-confirm-delete", attrs: { show: _vm.show } },
    [
      _c(
        "v-card",
        { staticClass: "mt-confirm-delete__card" },
        [
          _c("v-card-title", { staticClass: "mt-confirm-delete__title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("mt.views.file-manager.export-list.confirm-delete")
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "mt-confirm-delete__body" }, [
            _c("h4", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "mt.views.file-manager.export-list.confirm-delete-are-you-sure",
                      { noSelected: _vm.noSelected }
                    )
                  ) +
                  " "
              ),
            ]),
            _c("h4", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "mt.views.file-manager.export-list.confirm-delete-cannot-be-recovered"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "mt-confirm-delete__actions" },
            [
              _c("v-spacer"),
              _c("mt-cancel-button", {
                attrs: { disabled: _vm.loading },
                on: {
                  click: function ($event) {
                    return _vm.cancel()
                  },
                },
              }),
              _c("mt-delete-button", {
                attrs: { loading: _vm.loading },
                on: {
                  click: function ($event) {
                    return _vm.confirm()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }