var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-app-bar", { attrs: { app: "", flat: "" } }, [
    _c(
      "div",
      { staticClass: "flex-grow-1 d-flex align-center" },
      [
        _c(
          "v-btn",
          {
            staticClass: "hidden-lg-and-up",
            attrs: { icon: "", small: "" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.sidebar = !_vm.sidebar
              },
            },
          },
          [_c("v-icon", [_vm._v("fas fa-bars")])],
          1
        ),
        _c("v-spacer"),
        _c(
          "v-btn",
          {
            staticClass: "ml-auto",
            attrs: { icon: "", "x-small": "", fab: "" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.logout.apply(null, arguments)
              },
            },
          },
          [_c("v-icon", [_vm._v("fas fa-sign-out-alt")])],
          1
        ),
        _c("mt-language-menu"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }