
import { computed, defineComponent, Ref, ref, watch } from 'vue';
import { DataPoint } from '@/views/kym/components/MtDoughnutGraph';
import MtGraphCard from '@/views/kym/components/MtGraphCard.vue';
import { KymAccountTypeReport, KymAppReport } from '@/services/kym/KymAppReport';
import useTranslate from '@/hooks/useTranslate';
import useMessageService from '@/hooks/useMessageService';
import useKymAppReportService from '@/hooks/useKymAppReportService';
import { translateError } from '@/utils/error';
import { IdObject } from '@/models/IdObject';
import { array, string } from 'zod';
import { withUrlState } from '@/utils/withUrlState';
import { notNulldefined, notNulldefinedValue } from '@/utils/type-checks';

function getEntryValue<KEY extends string | number, VALUE>([, value]: [KEY, VALUE]): VALUE {
  return value;
}

function getAccountTypeI18n(accountType: string): string {
  const normalizedKey = accountType.toString().toLowerCase().replace(/_/g, '-');
  return `mt.models.account-types.${normalizedKey}`;
}

function withAccountTypesFilter() {
  const { getInitialState, updateUrl } = withUrlState();
  const t = useTranslate();

  const accountTypes = ['BANK', 'CREDIT_CARD', 'INSURANCE', 'LOAN', 'PENSION', 'POINT', 'STOCK', 'STORED_VALUE'];

  const accountTypes$ = computed<Array<IdObject>>(() =>
    accountTypes.map((accountType) => ({
      id: accountType,
      name: t(getAccountTypeI18n(accountType)),
    }))
  );

  const { selectedAccountTypes } = getInitialState({ selectedAccountTypes: array(string()) });
  const selectedAccountTypes$ = ref<Array<string>>(selectedAccountTypes ?? []);
  watch(selectedAccountTypes$, (selectedAccountTypes) => updateUrl({ selectedAccountTypes }));

  return {
    accountTypes$,
    selectedAccountTypes$,
  };
}

function withInstitutionsFilter(appReport$: Ref<KymAppReport | null | undefined>) {
  const { getInitialState, updateUrl } = withUrlState();
  const t = useTranslate();

  const institutionIds$ = computed<Array<string> | undefined>(() => {
    if (!appReport$.value) return;

    return Object.keys(appReport$.value.institutions);
  });

  const institutions$ = computed<Array<IdObject> | undefined>(() => {
    if (!institutionIds$.value) return;

    return institutionIds$.value.map((id) => ({
      id,
      name: t(`mt.institution-names.${id}`),
    }));
  });

  const { selectedInstitutions } = getInitialState({
    selectedInstitutions: array(string()),
  });

  const selectedInstitutions$ = ref<Array<string>>(selectedInstitutions ?? []);
  watch(selectedInstitutions$, (selectedInstitutions) => updateUrl({ selectedInstitutions }));

  return {
    institutions$,
    selectedInstitutions$,
  };
}

export default defineComponent({
  components: { MtGraphCard },
  setup() {
    const t = useTranslate();
    const messageService = useMessageService();
    const appReportService = useKymAppReportService();

    const currencyFormatter = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' });
    const pointsFormatter = new Intl.NumberFormat('ja-JP', { style: 'decimal' });

    const appReport$ = ref<KymAppReport | null | undefined>();

    const ASSETS_ACCOUNT_TYPES: Array<string> = ['BANK', 'INSURANCE', 'PENSION', 'STOCK', 'STORED_VALUE'];
    const LIABILITIES_ACCOUNT_TYPES: Array<string> = ['LOAN', 'CREDIT_CARD'];
    const POINT_ACCOUNT_TYPES: Array<string> = ['POINT'];

    const { accountTypes$, selectedAccountTypes$ } = withAccountTypesFilter();
    const { institutions$, selectedInstitutions$ } = withInstitutionsFilter(appReport$);

    const byAccountTypes$ = computed<{ [accountType: string]: KymAccountTypeReport | undefined } | undefined>(() => {
      const appReport = appReport$.value;
      if (!appReport) return;

      const selectedInstitutions = selectedInstitutions$.value;
      const showAllInstitutions = selectedInstitutions.length === 0;

      const selectedAccountTypes = selectedAccountTypes$.value;
      const showAllAccountTypes = selectedAccountTypes.length === 0;

      return Object.entries(appReport.institutions)
        .filter(notNulldefinedValue)
        .filter(([lid]) => showAllInstitutions || selectedInstitutions.includes(lid))
        .map(getEntryValue)
        .flatMap((institutionReport) => Object.entries(institutionReport))
        .filter(notNulldefinedValue)
        .filter(([accountType]) => showAllAccountTypes || selectedAccountTypes.includes(accountType))
        .reduce((map, [accountType, report]) => {
          const mapValue = map[accountType];
          if (mapValue) {
            mapValue.g += report.g;
            mapValue.ac += report.ac;
            mapValue.b += report.b;
          } else {
            map[accountType] = { ...report };
          }

          return map;
        }, {} as Record<string, KymAccountTypeReport | undefined>);
    });

    const guestsByAccountTypes$ = computed<Array<DataPoint> | undefined>(() => {
      const byAccountTypes = byAccountTypes$.value;
      if (!byAccountTypes) return;

      return Object.entries(byAccountTypes)
        .filter(notNulldefinedValue)
        .map(([accountType, report]) => ({
          id: accountType,
          name: getAccountTypeI18n(accountType),
          value: report.g,
        }));
    });

    function computeByInstitutions(options: {
      accountTypes?: Array<string>;
      valueFn: (report: KymAccountTypeReport) => number;
    }): () => Array<DataPoint> | undefined {
      return () => {
        const appReport = appReport$.value;
        if (!appReport) return;

        const selectedInstitutions = selectedInstitutions$.value;
        const showAllInstitutions = !selectedInstitutions.length;

        const selectedAccountTypes = selectedAccountTypes$.value;
        const showAllAccountTypes = !selectedAccountTypes.length;

        return Object.entries(appReport.institutions)
          .filter(notNulldefinedValue)
          .filter(([lid]) => showAllInstitutions || selectedInstitutions.includes(lid))
          .map(([lid, accountTypesMap]) => {
            const reports = Object.entries(accountTypesMap)
              .filter(notNulldefinedValue)
              .filter(([accountType]) => (options.accountTypes ? options.accountTypes.includes(accountType) : true))
              .filter(([accountType]) => showAllAccountTypes || selectedAccountTypes.includes(accountType))
              .map(([, report]) => report);

            if (reports.length === 0) return undefined;

            const value = reports.map(options.valueFn).reduce((sum, value) => sum + value, 0);

            return {
              id: lid,
              name: `mt.institution-names.${lid}`,
              value,
            };
          })
          .filter(notNulldefined);
      };
    }

    const guestsByInstitutions$ = computed<Array<DataPoint> | undefined>(
      computeByInstitutions({ valueFn: (report) => report.g })
    );
    const accountsByAccountTypes$ = computed<Array<DataPoint> | undefined>(() => {
      const byAccountTypes = byAccountTypes$.value;
      if (!byAccountTypes) return;

      return Object.entries(byAccountTypes)
        .filter(notNulldefinedValue)
        .map(([accountType, report]) => ({
          id: accountType,
          name: getAccountTypeI18n(accountType),
          value: report.ac,
        }));
    });
    const accountsByInstitutions$ = computed<Array<DataPoint> | undefined>(
      computeByInstitutions({ valueFn: (report) => report.ac })
    );

    function computeBalanceByAccountTypes(accountTypes: Array<string>): () => Array<DataPoint> | undefined {
      return () => {
        const byAccountTypes = byAccountTypes$.value;
        if (!byAccountTypes) return;

        return Object.entries(byAccountTypes)
          .filter(notNulldefinedValue)
          .filter(([accountType]) => accountTypes.includes(accountType))
          .map(([accountType, report]) => ({
            id: accountType,
            name: getAccountTypeI18n(accountType),
            value: report.b,
          }));
      };
    }

    const assetsByAccountTypes$ = computed<Array<DataPoint> | undefined>(
      computeBalanceByAccountTypes(ASSETS_ACCOUNT_TYPES)
    );
    const assetsByInstitutions$ = computed<Array<DataPoint> | undefined>(
      computeByInstitutions({
        accountTypes: ASSETS_ACCOUNT_TYPES,
        valueFn: (report) => report.b,
      })
    );
    const liabilitiesByAccountTypes$ = computed<Array<DataPoint> | undefined>(
      computeBalanceByAccountTypes(LIABILITIES_ACCOUNT_TYPES)
    );
    const liabilitiesByInstitutions$ = computed<Array<DataPoint> | undefined>(
      computeByInstitutions({
        accountTypes: LIABILITIES_ACCOUNT_TYPES,
        valueFn: (report) => report.b,
      })
    );
    const pointsByAccountTypes$ = computed<Array<DataPoint> | undefined>(
      computeBalanceByAccountTypes(POINT_ACCOUNT_TYPES)
    );
    const pointsByInstitutions$ = computed<Array<DataPoint> | undefined>(
      computeByInstitutions({
        accountTypes: POINT_ACCOUNT_TYPES,
        valueFn: (report) => report.b,
      })
    );

    async function retrieveAppReport(): Promise<void> {
      try {
        appReport$.value = await appReportService.get();
      } catch (e) {
        messageService.queueMessage({
          action: {
            autoTrigger: true,
            onClick: retrieveAppReport,
            text: 'mt.buttons.retry',
          },
          text: 'mt.views.kym.messages.could-not-load-data.title',
          secondaryText: translateError(e),
          type: 'error',
        });
      }

      if (appReport$.value === null) {
        messageService.queueMessage({
          type: 'info',
          text: 'mt.views.kym.messages.not-found.title',
          secondaryText: 'mt.views.kym.messages.not-found.subtitle',
        });
      }
    }
    retrieveAppReport();

    return {
      currencyFormatter,
      t,

      accountsByAccountTypes$,
      accountsByInstitutions$,
      accountTypes$,
      assetsByAccountTypes$,
      assetsByInstitutions$,
      guestsByAccountTypes$,
      guestsByInstitutions$,
      institutions$,
      liabilitiesByAccountTypes$,
      liabilitiesByInstitutions$,
      pointsByAccountTypes$,
      pointsByInstitutions$,
      pointsFormatter,
      selectedAccountTypes$,
      selectedInstitutions$,
    };
  },
});
