import Vue from 'vue';
import App from './App.vue';
import dayjs from 'dayjs';
import dayjsDurationPlugin from 'dayjs/plugin/duration';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import dayjsTimezonePlugin from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ja';
import * as Sentry from '@sentry/vue';
import * as sentry from '@/services/sentry';
import * as configs from '@/configs';
import * as Locale from '@/models/Locale';
import { persistedRef } from '@/utils/persistedRef';
import { SimpleMessageService } from '@/components/MtMessages/MessageService';
import { configureI18n } from '@/i18n';
import { ApiFileManagerService } from '@/services/FileManagerService/ApiFileManagerService';
import * as RequestConfigurer from '@/services/fetch/RequestConfigurer';
import { corsConfigurer } from '@/services/fetch/corsConfigurer';
import { ApiFinancialInstitutionsService } from '@/services/FinancialInstitutionsService/ApiFinancialInstitutionsService';
import { InstitutionNamesProvider } from '@/services/InstitutionNamesProvider';
import { ApiKymAppReportService } from '@/services/kym/ApiKymAppReportService';
import { delegateUnhandledRejectionHandler } from '@/services/errors/delegateUnhandledRejectionHandler';
import { UnexpectedErrorHandler } from '@/services/errors/UnexpectedErrorHandler';
import { NavigationDuplicatedErrorHandler } from '@/services/errors/NavigationDuplicatedErrorHandler';
import { clearMessagesGuard } from '@/router/clearMessagesGuard';
import { authenticatedGuard } from '@/router/authenticatedGuard';
import { AuthTokenExpiredErrorHandler } from '@/services/errors/AuthTokenExpiredErrorHandler';
import { CognitoAuthService } from '@/services/auth/CognitoAuthService';
import { tokenAuthenticationConfigurer } from '@/services/fetch/tokenAuthenticationConfigurer';
import { configureVuetify } from '@/plugins/vuetify';
import { configureRouter } from './router';

dayjs.extend(dayjsDurationPlugin);
dayjs.extend(dayjsUtcPlugin);
dayjs.extend(dayjsTimezonePlugin);
dayjs.extend(localizedFormat);

const main = async () => {
  const sentrySdkConfig = configs.sentrySdk.fromEnv();
  const sentryScopeConfig = configs.sentryScope.fromEnv();

  if (sentrySdkConfig.success) {
    sentry.setupSdk(sentrySdkConfig.data);
    if (sentryScopeConfig.success) {
      sentry.setupScope(sentryScopeConfig.data);
    } else {
      Sentry.captureException(sentryScopeConfig.error);
    }
  } else {
    console.error(`Invalid Sentry SDK configuration: ${sentrySdkConfig.error}`);
  }

  const appConfig = configs.app.fromEnvUnsafe();
  const logger = sentry.toLogger();
  const authService = new CognitoAuthService({
    baseURI: document.baseURI,
    cognito: configs.cognito.fromAppConfig(appConfig),
  });
  const messageService = new SimpleMessageService();
  const router = configureRouter({
    routerHooks: [authenticatedGuard({ authService }), clearMessagesGuard({ messageService })],
  });
  const configureRequest = RequestConfigurer.pipe([tokenAuthenticationConfigurer({ authService }), corsConfigurer]);
  const locale = persistedRef<Locale.Locale>({
    key: appConfig.storageKeyLocale,
    default: appConfig.defaultLocale,
    shape: Locale.Locale,
  });
  const i18n = configureI18n({ logger, locale });
  const fileManagerService = new ApiFileManagerService({
    locale,
    configureRequest,
    baseUrl: appConfig.apiBaseUrl,
  });
  const kymAppReportService = new ApiKymAppReportService({ locale, configureRequest, baseUrl: appConfig.apiBaseUrl });
  const institutionsService = new ApiFinancialInstitutionsService({
    locale,
    configureRequest,
    baseUrl: appConfig.apiBaseUrl,
  });
  const i18nMessageProvider = new InstitutionNamesProvider({ institutionsService });
  const vuetify = configureVuetify({ i18n });

  // If false, the entire block will be minified away, which removes the dependencies from the build as well.
  if (appConfig.nodeEnv === 'development') {
    const { startMockServer } = await import('@/mock-server');
    await startMockServer();
  }
  // Avoid directly assigning to onunhandledrejection. This seems to prevent Sentry from catching async errors
  window.addEventListener(
    'unhandledrejection',
    delegateUnhandledRejectionHandler({
      promiseRejectionEventHandlers: [
        // The order of error handlers matter
        new AuthTokenExpiredErrorHandler({ authService }),
        new NavigationDuplicatedErrorHandler(),
        new UnexpectedErrorHandler({ messageService }),
      ],
    })
  );
  new Vue({
    i18n,
    router,
    vuetify,
    render: (h) =>
      h(App, {
        props: {
          i18n,
          i18nMessageProvider,
          appConfig,
          authService,
          messageService,
          fileManagerService,
          kymAppReportService,
          institutionsService,
          router,
          logger,
          locale: () => locale,
        },
      }),
  }).$mount('#app');
};

main();
