import { onBeforeMount, onUnmounted } from 'vue';
import useAuthService from '@/hooks/useAuthService';

const FIVE_MINS_IN_MS = 5 * 60 * 1000;
const DEFAULT_TIMEOUT = FIVE_MINS_IN_MS;

// Performs auth checks at a regular interval when used in a mounted component.
export function useIntervalAuthCheck(timeout: number = DEFAULT_TIMEOUT): void {
  const authService = useAuthService();
  let authIntervalId: number | null = null;

  onBeforeMount(() => {
    authIntervalId = window.setInterval(authService.ensureAuthentication, timeout);
  });

  onUnmounted(() => {
    if (authIntervalId) {
      window.clearInterval(authIntervalId);
      authIntervalId = null;
    }
  });
}

export default useIntervalAuthCheck;
