var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "div",
        { staticClass: "mt-bottom-card" },
        [
          _c(
            "v-overlay",
            {
              staticClass: "mt-bottom-card__overlay",
              attrs: { absolute: true, dark: false, opacity: "0" },
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "flex-column mt-bottom-card__container",
                  attrs: { fluid: "", "fill-height": "" },
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-card",
                    {
                      staticClass: "mt-bottom-card__card",
                      attrs: { outlined: "", elevation: "3" },
                    },
                    [_vm._t("default")],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }