var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-list",
    { attrs: { dense: "" } },
    [
      _c("v-subheader", { staticClass: "mt-2 mb-4", attrs: { dense: "" } }, [
        _vm._v(_vm._s(_vm.$t("mt.sidebar.dashboard"))),
      ]),
      _c(
        "v-list-item-group",
        {
          model: {
            value: _vm.item$,
            callback: function ($$v) {
              _vm.item$ = $$v
            },
            expression: "item$",
          },
        },
        _vm._l(_vm.items$, function (item, i) {
          return _c(
            "v-list-item",
            { key: i, attrs: { link: "" } },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { fab: "" } }, [
                    _vm._v(_vm._s(item.icon)),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(_vm.$t(item.name)))])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }