import { provide, inject, InjectionKey } from 'vue';
import { unit, KymAppReportService } from '@/services/kym/KymAppReportService';

const injectionKey = Symbol('kymAppReportService') as InjectionKey<KymAppReportService>;

export function provideKymAppReportService(config: KymAppReportService): void {
  return provide(injectionKey, config);
}

/**
 * Composition function that returns Config
 */
function useKymAppReportService(): KymAppReportService {
  return inject(injectionKey, unit);
}

export default useKymAppReportService;
