
import { defineComponent, toRefs } from 'vue';
import { MtOverlayEvents, MtOverlayProps } from './MtOverlay';
import { VOverlay } from 'vuetify/lib';
import { useEmitter } from '@/utils/useEmitter';
import useLayoutRect from '@/hooks/useLayoutRect';
import useDomRectOffsetStyle from '@/hooks/useDomRectOffsetStyle';

export default defineComponent({
  components: { VOverlay },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: MtOverlayProps) {
    const { show: propShow } = toRefs(props);
    const emit = useEmitter<MtOverlayEvents>();
    const layoutRect$ = useLayoutRect();
    const layoutOffsetStyle$ = useDomRectOffsetStyle(layoutRect$);

    function clickOutside(): void {
      // Make sure we only emit while the overlay is open.
      if (propShow.value) {
        emit('click:outside', {});
      }
    }

    return {
      clickOutside,
      layoutOffsetStyle$,
      overlayValue: propShow,
      overlayDark: false,
    };
  },
});
