
import { computed, defineComponent, ref } from 'vue';
import { MaybeElement } from '@vueuse/core';
import { MtAdminAppBar } from '@/components/MtAdminAppBar';
import MtSidebar from '@/components/MtSidebar/MtSidebar.vue';
import MtMessages from '@/components/MtMessages/MtMessages.vue';
import { provideLayoutRect } from '@/hooks/useLayoutRect';
import { provideSidebarState } from '@/hooks/useSidebarState';
import useAppSetupState from '@/hooks/useAppSetupState';
import useDomRect from '@/hooks/useDomRect';

export default defineComponent({
  components: {
    MtMessages,
    MtSidebar,
    MtAdminAppBar,
  },
  setup() {
    const main = ref<MaybeElement>();
    const mainRect = useDomRect(main);
    const appSetupState = useAppSetupState();
    const loading = computed<boolean>(() => appSetupState.value.status === 'loading');

    provideLayoutRect(mainRect);
    provideSidebarState(ref(null));

    return {
      loading,
      main,
    };
  },
});
