var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c("mt-sidebar"),
      _c("mt-admin-app-bar"),
      _c(
        "v-main",
        { ref: "main", staticClass: "mt-dashboard__main" },
        [
          _vm.loading
            ? _c("v-progress-linear", {
                attrs: { active: "", indeterminate: "" },
              })
            : _vm._e(),
          !_vm.loading ? _c("router-view") : _vm._e(),
        ],
        1
      ),
      _c("mt-messages"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }