import { provide, inject, InjectionKey } from 'vue';
import { unit, I18nMessageProvider } from '@/services/I18nMessageProvider';

const injectionKey = Symbol('i18nMessageProvider') as InjectionKey<I18nMessageProvider>;

export function provideI18nMessageProvider(provider: I18nMessageProvider): void {
  return provide(injectionKey, provider);
}

/**
 * Composition function that returns I18nMessageProvider
 */
function useI18nMessageProvider(): I18nMessageProvider {
  return inject(injectionKey, unit);
}

export default useI18nMessageProvider;
