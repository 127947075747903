
import { computed, defineComponent, ref, toRefs, watch, PropType } from 'vue';
import { useEmitter } from '@/utils/useEmitter';
import { VDatePicker } from 'vuetify/lib';
import { MtDateRangePickerEvents, MtDateRangePickerProps } from './MtDateRangePicker';
import useTranslate from '@/hooks/useTranslate';
import useAppConfig from '@/hooks/useAppConfig';
import useLocale from '@/hooks/useLocale';
import dayjs from 'dayjs';

export default defineComponent({
  components: {
    VDatePicker,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    landscape: {
      type: Boolean,
      default: false,
    },
    disableFutureDates: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: MtDateRangePickerProps) {
    const appConfig = useAppConfig();
    const t = useTranslate();
    const emitter = useEmitter<MtDateRangePickerEvents>();
    const locale$ = useLocale();
    const { dateRange, disableFutureDates } = toRefs(props);
    const sDateRangeModel = ref<string[]>([]);

    // Vuetify datepicker has some weird behavior when displaying a range with only 1 date.
    // So create separate ranges for each datepicker which avoids having 1 date.
    const fromDateRange = computed(() => {
      const fromDate = sDateRangeModel.value[0];
      const toDate = sDateRangeModel.value[1];
      if (!fromDate) {
        // fromDate not selected, so keep the 'from' picker in an unselected state.
        return [];
      }
      if (!toDate) {
        // toDate not available, so set both dates to fromDate for the 'from' picker.
        return [fromDate, fromDate];
      }
      return [fromDate, toDate];
    });
    const toDateRange = computed(() => {
      const fromDate = sDateRangeModel.value[0];
      const toDate = sDateRangeModel.value[1];
      if (!toDate) {
        // toDate not selected, so keep the 'to' picker in an unselected state.
        return [];
      }
      if (!fromDate) {
        // fromDate not available, so set both dates to toDate for the 'to' picker.
        return [toDate, toDate];
      }
      return [fromDate, toDate];
    });

    const maxDate = computed(() => {
      if (!disableFutureDates.value) {
        return undefined;
      }
      return dayjs().tz(appConfig.timezone).format('YYYY-MM-DD');
    });

    watch(
      () => [...dateRange.value],
      (newDateRange) => {
        if (newDateRange[0] !== sDateRangeModel.value[0] || newDateRange[1] !== sDateRangeModel.value[1]) {
          sDateRangeModel.value = newDateRange;
        }
      }
    );

    function onFromPickerInput(eventDateRange: string[]): void {
      const [newFromDate] = eventDateRange;
      const newToDate = sDateRangeModel.value[1];
      sDateRangeModel.value = [newFromDate, newToDate];
      sendInputEvents();
    }

    function onToPickerInput(eventDateRange: string[]): void {
      const [newToDate] = eventDateRange;
      const newFromDate = sDateRangeModel.value[0];
      sDateRangeModel.value = [newFromDate, newToDate];
      sendInputEvents();
    }

    function sendInputEvents(): void {
      // Send an input on any selection
      emitter('input', { dateRange: sDateRangeModel.value });

      // Only send a change event when both dates are selected.
      if (sDateRangeModel.value[0] && sDateRangeModel.value[1]) {
        emitter('change', { dateRange: sDateRangeModel.value });
      }
    }

    function formatDay(dateString: string): string {
      // Prevents v-date-picker from attaching 日 suffix to dates on JA locale
      // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VDatePicker/util/createNativeLocaleFormatter.ts#L20-L40
      // Date will always be valid since v-date-picker will call the function with  a valid ISO 8601 date string
      return `${new Date(Date.parse(dateString)).getUTCDate()}`;
    }

    return {
      t,
      onFromPickerInput,
      onToPickerInput,
      maxDate,
      sDateRangeModel,
      fromDateRange,
      toDateRange,
      formatDay,
      locale: locale$,
    };
  },
});
