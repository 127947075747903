
// External Imports
import { computed, defineComponent, ref, toRefs, watch } from 'vue';
import dayjs from 'dayjs';
// Project Imports
import { useEmitter } from '@/utils/useEmitter';
import { MtButton, MtClearButton, MtCancelButton } from '@/components/MtButton';
import MtOverlay from '@/components/MtOverlay/MtOverlay.vue';
import MtDateRangePicker from '@/components/MtDateRangePicker/MtDateRangePicker.vue';
import { DateRangeEvent } from '@/components/MtDateRangePicker';
import useTranslate from '@/hooks/useTranslate';
import useAppConfig from '@/hooks/useAppConfig';
// Local Imports
import usePreparedQuery from './usePreparedQuery';
import { MtTransactionExportModalEvents, MtTransactionExportModalProps } from './MtTransactionExportModal';

export default defineComponent({
  components: { MtButton, MtClearButton, MtCancelButton, MtDateRangePicker, MtOverlay },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: MtTransactionExportModalProps) {
    const appConfig = useAppConfig();
    const t = useTranslate();
    const emit = useEmitter<MtTransactionExportModalEvents>();

    const { show: propShow } = toRefs(props);
    const isOverlayOpen = ref<boolean>(false);
    const dateRange = ref<string[]>([]);
    const isDateRangeEmpty = computed<boolean>(() => dateRange.value.length === 0);
    const isDateRangeSelected = computed<boolean>(() => !!dateRange.value[0] && !!dateRange.value[1]);

    const transactionsQuery = usePreparedQuery('filemanagerTransactions');

    function getMinDateStr() {
      const today = dayjs().tz(appConfig.timezone);
      const minDate = today.subtract(appConfig.transactionExportMaxMonths, 'month');
      return minDate.format('YYYY-MM-DD');
    }

    // Within the date range limit when the date is x months before the current date
    // For example assuming a limit of 3 months and today is 2021-05-15 the earliest valid date is 2021-02-15.
    // And in the case of 2021-05-31 the earliest valid date becomes 2021-03-01 because 2021-02-31 is not a real date.
    // We disable future dates on the picker, so we only need to check the valid past date.
    // We don't disable the past date in the picker because we want to show a message about upgrading instead.
    const isDateRangeWithinLimit = computed<boolean>(
      () => !dateRange.value[0] || dateRange.value[0] >= getMinDateStr()
    );

    watch(propShow, (newShow) => {
      if (isOverlayOpen.value !== newShow) {
        isOverlayOpen.value = newShow;
      }
    });

    function resetSelection(): void {
      dateRange.value = [];
    }

    function closeOverlay(): void {
      if (isOverlayOpen.value) {
        isOverlayOpen.value = false;
        dateRange.value = [];
        emit('close', {});
      }
    }

    function setDateRange({ dateRange: newDateRange }: DateRangeEvent): void {
      dateRange.value = newDateRange;
    }

    async function requestTransactionsFile(): Promise<void> {
      const queryParams = { fromDate: dateRange.value[0], toDate: dateRange.value[1] };
      const requestedExport = await transactionsQuery.requestExport(queryParams);
      if (requestedExport) {
        emit('requestSuccess', requestedExport);
        closeOverlay();
      }
    }

    return {
      t,
      setDateRange,
      requestTransactionsFile,
      resetSelection,
      closeOverlay,
      dateRange,
      transactionExportMaxMonths: appConfig.transactionExportMaxMonths,
      isDateRangeWithinLimit,
      isOverlayOpen,
      isDateRangeEmpty,
      isDateRangeSelected,
      isLoading: transactionsQuery.exportLoading,
    };
  },
});
