var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "mt-overlay",
    {
      staticClass: "mt-transaction-export",
      attrs: { show: _vm.isOverlayOpen },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "mt-transaction-export__title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("mt.views.file-manager.choose-date-range")) +
                " "
            ),
          ]),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    [
                      _c("mt-date-range-picker", {
                        attrs: {
                          disabled: _vm.isLoading,
                          dateRange: _vm.dateRange,
                          landscape: "",
                          disableFutureDates: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setDateRange($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
              !_vm.isDateRangeWithinLimit
                ? _c(
                    "v-row",
                    [
                      _c("v-spacer"),
                      _c("v-col", [
                        _c(
                          "div",
                          {
                            staticClass: "mt-transaction-export__limit-warning",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "mt.views.file-manager.transaction-export.date-selection-limit-exceeded",
                                    {
                                      noOfMonths:
                                        _vm.transactionExportMaxMonths,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("v-spacer"),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "mt-transaction-export__actions" },
            [
              _c("v-spacer"),
              _c("mt-cancel-button", {
                attrs: { disabled: _vm.isLoading },
                on: {
                  click: function ($event) {
                    return _vm.closeOverlay()
                  },
                },
              }),
              _c("mt-clear-button", {
                attrs: { disabled: _vm.isLoading || _vm.isDateRangeEmpty },
                on: {
                  click: function ($event) {
                    return _vm.resetSelection()
                  },
                },
              }),
              _c(
                "mt-button",
                {
                  attrs: {
                    disabled:
                      !_vm.isDateRangeSelected ||
                      _vm.isLoading ||
                      !_vm.isDateRangeWithinLimit,
                    elevation: "1",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.requestTransactionsFile()
                    },
                  },
                },
                [
                  _vm.isLoading
                    ? _c("v-progress-circular", {
                        attrs: { size: 16, width: 3, indeterminate: "" },
                      })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.$t("mt.buttons.export")) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }