var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-navigation-drawer", {
    attrs: { app: "", floating: "", light: "", color: "#e5ffea" },
    scopedSlots: _vm._u([
      {
        key: "prepend",
        fn: function () {
          return [
            _c(
              "v-toolbar",
              { attrs: { flat: "", light: "", color: "#e5ffea" } },
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/" } },
                      [_c("mt-logo")],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("mt-sidebar-menu", { staticClass: "pt-4" }),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.sidebar,
      callback: function ($$v) {
        _vm.sidebar = $$v
      },
      expression: "sidebar",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }