var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c("v-card-title", { staticClass: "justify-center text-subtitle-1" }, [
        _vm._v(_vm._s(_vm.t(_vm.title))),
      ]),
      _c(
        "v-card-text",
        { staticClass: "d-flex flex-column align-center" },
        [
          _vm.graphData$
            ? [
                _vm.graphData$
                  ? _c("mt-doughnut-graph", {
                      staticClass: "graph my-4",
                      attrs: {
                        "color-palette": _vm.backgroundColors$,
                        data: _vm.graphData$,
                        "value-formatter": _vm.valueFormatter,
                      },
                    })
                  : _vm._e(),
              ]
            : [
                !_vm.graphData$
                  ? _c("v-progress-circular", {
                      staticClass: "my-4",
                      attrs: { indeterminate: "", size: "300" },
                    })
                  : _vm._e(),
              ],
          _c("v-simple-table", { staticClass: "card-table" }, [
            _c("thead", [
              _c("tr", [
                _c("th"),
                _c("th"),
                _c("th", [_vm._v(" " + _vm._s(_vm.t(_vm.labelTitle)) + " ")]),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.t(_vm.valueTitle)) + " "),
                ]),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.t(_vm.percentageTitle)) + " "),
                ]),
              ]),
            ]),
            _vm.limitedData$
              ? _c(
                  "tbody",
                  [
                    _vm._l(_vm.limitedData$, function (point, i) {
                      return _c("tr", { key: point.id }, [
                        _c("td"),
                        _c(
                          "td",
                          [
                            _c("v-chip", {
                              attrs: {
                                "x-small": "",
                                color: _vm.backgroundColors$[i],
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [_vm._v(_vm._s(_vm.t(point.name)))]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(_vm.valueFormatter.format(point.value))
                          ),
                        ]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(_vm.percentages$[i])),
                        ]),
                      ])
                    }),
                    _vm.others$
                      ? _c("tr", [
                          _c(
                            "td",
                            [
                              _vm.showOthers$
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "icon--visibility",
                                      attrs: { fa: "", "x-small": "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.showOthers$ = !_vm.showOthers$
                                        },
                                      },
                                    },
                                    [_vm._v(" fas fa-eye ")]
                                  )
                                : _vm._e(),
                              !_vm.showOthers$
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "icon--visibility",
                                      attrs: { fa: "", "x-small": "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.showOthers$ = !_vm.showOthers$
                                        },
                                      },
                                    },
                                    [_vm._v(" fas fa-eye-slash ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("v-chip", {
                                attrs: {
                                  "x-small": "",
                                  color:
                                    _vm.backgroundColors$[
                                      _vm.backgroundColors$.length - 1
                                    ],
                                },
                              }),
                            ],
                            1
                          ),
                          _c("td", [_vm._v(_vm._s(_vm.t(_vm.others$.name)))]),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.valueFormatter.format(_vm.others$.value)
                              )
                            ),
                          ]),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(_vm._s(_vm.othersPercentage$)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.filteredPoint$
                      ? _c("tr", [
                          _c("td"),
                          _c("td"),
                          _c("td", [
                            _vm._v(_vm._s(_vm.t(_vm.filteredPoint$.name))),
                          ]),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.valueFormatter.format(
                                  _vm.filteredPoint$.value
                                )
                              )
                            ),
                          ]),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(_vm._s(_vm.filteredPercentage$)),
                          ]),
                        ])
                      : _vm._e(),
                    _c("tr", { staticClass: "card-table__total-row" }, [
                      _c("td"),
                      _c("td"),
                      _c("td", [
                        _vm._v(_vm._s(_vm.t("mt.views.kym.tables.total"))),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.valueFormatter.format(_vm.total$))),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.percentageFormatter$.format(1))),
                      ]),
                    ]),
                  ],
                  2
                )
              : _c(
                  "tbody",
                  [
                    _vm._l(_vm.limit + 1, function (i) {
                      return _c("tr", { key: i }, [
                        _c("td"),
                        _c(
                          "td",
                          [
                            _c("v-chip", {
                              attrs: { "x-small": "", color: "transparent" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("v-skeleton-loader", {
                              attrs: { type: "table-cell" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "d-flex flex-column align-end",
                              attrs: { type: "table-cell" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "d-flex flex-column align-end",
                              attrs: { type: "table-cell" },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    _c("tr", { staticClass: "card-table__total-row" }, [
                      _c("td"),
                      _c("td"),
                      _c("td", [
                        _vm._v(_vm._s(_vm.t("mt.views.kym.tables.total"))),
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "d-flex flex-column align-end",
                            attrs: { type: "table-cell" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "d-flex flex-column align-end",
                            attrs: { type: "table-cell" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }