import { provide, inject, InjectionKey } from 'vue';
import * as AuthService from '@/services/auth/AuthService';

const authServiceInjectionKey = Symbol('authService') as InjectionKey<AuthService.AuthService>;

export function provideAuthService(autherService: AuthService.AuthService): void {
  return provide(authServiceInjectionKey, autherService);
}

/**
 * Composition function that returns AuthService
 */
function useAuthService(): AuthService.AuthService {
  return inject(authServiceInjectionKey, AuthService.unit);
}

export default useAuthService;
