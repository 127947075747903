
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
});
