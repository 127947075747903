import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';
import { Props } from '@/types/vue';

export interface MtBottomCardData {}

export interface MtBottomCardMethods {}

export interface MtBottomCardComputed {}

export interface MtBottomCardProps extends Props {
  show: boolean;
}

export type MtBottomCardEvents = Record<string, never>;

export type MtBottomCardModalInstance = CombinedVueInstance<
  Vue,
  MtBottomCardData,
  MtBottomCardMethods,
  MtBottomCardComputed,
  MtBottomCardProps,
  void
>;
