import { IdObject } from '@/models/IdObject';
import { FinancialInstitutionsService, InstitutionNamesResponse } from '@/services/FinancialInstitutionsService';
import { ApiService } from '@/services/ApiService';
import { notNulldefinedValue } from '@/utils/type-checks';

export class ApiFinancialInstitutionsService extends ApiService implements FinancialInstitutionsService {
  protected institutionsCache: Record<string, Array<IdObject>> = {};

  async get(locale?: string): Promise<Array<IdObject>> {
    if (!locale) locale = this._locale$.value;

    if (this.institutionsCache[locale]) {
      return this.institutionsCache[locale];
    }
    // Else not in the cache, so we fetch the data.

    const response = await this.fetch(`${this._API_BASE_URL}/data-assets/institution-names?locale=${locale}`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) throw response;

    const parsedResponse: InstitutionNamesResponse = await response.json();
    const institutions = Object.entries(parsedResponse)
      .filter(notNulldefinedValue)
      .map(([fid, metadata]) => ({ id: fid, name: metadata.display_name }));

    // Cache the result so that we can reuse it without needing to fetch again.
    this.institutionsCache[locale] = institutions;

    return institutions;
  }
}
