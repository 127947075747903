import { Route } from 'vue-router';

/**
 * Remove once vue-router supports 2.7 https://github.com/vuejs/vue-router/issues/3760
 **/
export type RouterMetadata = Readonly<{
  currentRoute: Route;
  previousRoute: Route | null;
}>;

export const unit: RouterMetadata = {
  // https://github.com/vuejs/router/blob/5b8983d04592c1420837c4533ee3b6cf6a07a314/packages/router/src/types/index.ts#L411-L421
  currentRoute: {
    path: '/',
    name: undefined,
    params: {},
    query: {},
    hash: '',
    fullPath: '/',
    matched: [],
    meta: {},
    redirectedFrom: undefined,
  },
  previousRoute: null,
};
