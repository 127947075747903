import { render, staticRenderFns } from "./MtDoughnutGraph.vue?vue&type=template&id=8e0c0654&"
import script from "./MtDoughnutGraph.vue?vue&type=script&lang=ts&"
export * from "./MtDoughnutGraph.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/project/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8e0c0654')) {
      api.createRecord('8e0c0654', component.options)
    } else {
      api.reload('8e0c0654', component.options)
    }
    module.hot.accept("./MtDoughnutGraph.vue?vue&type=template&id=8e0c0654&", function () {
      api.rerender('8e0c0654', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/kym/components/MtDoughnutGraph.vue"
export default component.exports