import { Ref } from 'vue';
import { RequestConfigurer } from '@/services/fetch/RequestConfigurer';
import { removeTrailingSlash } from '@/utils/url';

export type Config = {
  locale: Ref<string>;
  baseUrl: string;
  configureRequest: RequestConfigurer;
};

export abstract class ApiService {
  protected readonly _locale$: Ref<string>;
  protected readonly _API_BASE_URL: string;
  protected readonly _configureRequest: RequestConfigurer;

  // We may want to skip adding it to an implementation
  // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
  constructor(config: Config) {
    this._locale$ = config.locale;

    this._API_BASE_URL = removeTrailingSlash(config.baseUrl);

    this._configureRequest = config.configureRequest;
  }

  protected async fetch(input: RequestInfo, init: RequestInit = {}): Promise<Response> {
    // FIXME: Centralize error handling

    init = await this._configureRequest(input, init);

    return fetch(input, init);
  }
}
