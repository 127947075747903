var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-overlay",
    {
      staticClass: "mt-overlay",
      style: _vm.layoutOffsetStyle$,
      attrs: { value: _vm.overlayValue, dark: _vm.overlayDark },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.clickOutside,
              expression: "clickOutside",
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }