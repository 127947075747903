import merge from 'lodash.merge';
import { RequestConfigurer } from '@/services/fetch/RequestConfigurer';

export const corsConfigurer: RequestConfigurer = async (_input, init) => {
  return merge(
    {
      mode: 'cors',
      credentials: 'same-origin',
    },
    init
  );
};
