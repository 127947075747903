import { AuthService } from '@/services/auth/AuthService';
import { Auth } from '@aws-amplify/auth';
import * as cognito from '@/configs/cognito';

export type Config = {
  baseURI: string;
  cognito: cognito.Config;
};

export class CognitoAuthService implements AuthService {
  protected _authenticationPromise: Promise<void> | undefined;

  constructor(config: Config) {
    let baseURI = config.baseURI;
    baseURI = baseURI.split('#')[0];
    baseURI = baseURI.split('?')[0];
    baseURI = baseURI.endsWith('/') ? baseURI : baseURI + '/';

    Auth.configure({
      region: config.cognito.region,
      userPoolWebClientId: config.cognito.clientId,
      userPoolId: config.cognito.userPoolId,
      mandatorySignIn: false,

      // Cognito hosted UI configuration
      oauth: {
        domain: `${config.cognito.hostedUiDomainPrefix}.auth.${config.cognito.region}.amazoncognito.com`,
        redirectSignIn: baseURI,
        redirectSignOut: baseURI,
        clientId: config.cognito.clientId,
        responseType: 'code',
      },
    });
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      // Note: If the auth token is expired, but a valid refresh token is present this will refresh the session.
      // idToken is used to authenticate with services
      const session = await Auth.currentSession();
      // The user may have an invalid session if they have initially logged in but have a challenge to resolve.
      // For example if they need to change their password or enter an MFA token.
      return session.isValid();
    } catch (e) {
      return false;
    }
  }

  initiateAuthentication(): void {
    window.location.href = '/login.html';
  }

  // Checks the user's current authentication status and initiates authentication if the user is not authenticated.
  // Using arrow function to make sure that `this` keyword references this object instance.
  ensureAuthentication = async (): Promise<void> => {
    const isAuthenticated = await this.isAuthenticated();
    if (!isAuthenticated) {
      console.log('Not Authenticated. Initiating Authentication.');
      this.initiateAuthentication();
    }
  };

  async logout(): Promise<void> {
    await Auth.signOut();
    this.initiateAuthentication();
  }

  async getToken(): Promise<string> {
    let session, idToken, jwtToken;

    try {
      session = await Auth.currentSession();
    } catch (e) {
      console.error('Auth.currentSession() failed:', e);
      throw e;
    }

    try {
      idToken = session.getIdToken();
    } catch (e) {
      console.error('session.getIdToken() failed:', e);
      throw e;
    }

    try {
      jwtToken = idToken.getJwtToken();
    } catch (e) {
      console.error('idToken.getJwtToken() failed:', e);
      throw e;
    }

    return jwtToken;
  }
}
