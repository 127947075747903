
import { defineComponent } from 'vue';
import useSidebarState from '@/hooks/useSidebarState';
import MtLogo from '@/components/MtLogo/MtLogo.vue';
import MtSidebarMenu from '@/components/MtSidebarMenu/MtSidebarMenu.vue';

export default defineComponent({
  components: { MtSidebarMenu, MtLogo },
  setup() {
    const sidebar = useSidebarState();

    return { sidebar };
  },
});
