// TODO: rename to isDefined

export function notNulldefined<VALUE>(value: VALUE | undefined | null): value is VALUE {
  return value !== undefined && value !== null;
}

// TODO: rename to isEntryValueDefined

export function notNulldefinedValue<KEY extends string | number, VALUE>(
  value: [KEY, VALUE | null | undefined]
): value is [KEY, VALUE] {
  // "value" is an entry from Object.entries, so the actual value is in the first element
  return value[1] !== undefined && value[1] !== null;
}
