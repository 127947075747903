import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';
import { Props } from '@/types/vue';

export interface MtConfirmDeleteOverlayData {}

export interface MtConfirmDeleteOverlayMethods {}

export interface MtConfirmDeleteOverlayComputed {}

export interface MtConfirmDeleteOverlayProps extends Props {
  loading: boolean;
  show: boolean;
  noSelected: number;
}

export type MtConfirmDeleteOverlayEvents = {
  confirm: Record<string, never>;
  cancel: Record<string, never>;
};

export type MtConfirmDeleteOverlayModalInstance = CombinedVueInstance<
  Vue,
  MtConfirmDeleteOverlayData,
  MtConfirmDeleteOverlayMethods,
  MtConfirmDeleteOverlayComputed,
  MtConfirmDeleteOverlayProps,
  void
>;
