
import { defineComponent, computed, toRefs, PropType } from 'vue';
import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, ChartData, ChartOptions } from 'chart.js';
import { DataPoint } from '@/views/kym/components/MtDoughnutGraph';
import useI18n from '@/hooks/useI18n';

ChartJS.register(ArcElement, Tooltip);

export default defineComponent({
  components: {
    Doughnut,
  },
  props: {
    colorPalette: {
      type: Array as PropType<ReadonlyArray<string>>,
      default: () => [],
    },
    data: {
      type: Array as PropType<ReadonlyArray<DataPoint>>,
      default: () => [],
    },
    cutoutPercentage: {
      type: Number,
      default: 60,
    },
    valueFormatter: {
      type: Intl.NumberFormat,
      default: () => {
        return new Intl.NumberFormat('ja-JP', { style: 'decimal' });
      },
    },
  },
  setup(props) {
    const props$ = toRefs(props);
    const i18n = useI18n();
    const chartData = computed((): ChartData<'doughnut', number[]> => {
      const [labels, data] = props$.data.value.reduce(
        (acc, dataPoint): typeof acc => [acc[0].concat(dataPoint.name || ''), acc[1].concat(Math.abs(dataPoint.value))],
        [[], []] as [string[], number[]]
      );
      if (data.every((x) => x === 0)) {
        return {
          labels: [],
          datasets: [
            {
              data: [1],
              backgroundColor: ['transparent'],
              borderWidth: 1,
              borderColor: '#e0e0e0',
            },
          ],
        };
      }
      return {
        labels,
        datasets: [
          {
            data,
            backgroundColor: props$.colorPalette.value,
            borderWidth: 0,
          },
        ],
      };
    });
    const chartOptions = computed((): ChartOptions<'doughnut'> => {
      return {
        cutout: props$.cutoutPercentage.value,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: props$.data.value.some((dataPoint) => dataPoint.value !== 0),
            callbacks: {
              label(item) {
                try {
                  const value = props$.data.value[item.dataIndex].value;
                  const formattedValue = props$.valueFormatter.value.format(value);
                  const label = i18n.t(item.label);

                  // The leading space is important since there's no easy way to separate the label from the
                  // color square (the chart is drawn in a canvas, so there's no way to style it)
                  return ` ${label}: ${formattedValue}`;
                } catch (e) {
                  console.warn(`Error while generating tooltip`, e);
                  return '';
                }
              },
            },
          },
        },
      };
    });

    return {
      chartData,
      chartOptions,
    };
  },
});
