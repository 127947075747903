var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("doughnut", {
    attrs: { "chart-data": _vm.chartData, "chart-options": _vm.chartOptions },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }