var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-date-range-picker" },
    [
      _c(
        "v-date-picker",
        {
          staticClass: "mt-date-range-picker__date-picker",
          attrs: {
            range: "",
            "no-title": "",
            disabled: _vm.disabled,
            locale: _vm.locale,
            value: _vm.fromDateRange,
            max: _vm.sDateRangeModel[1] || _vm.maxDate,
            dayFormat: _vm.formatDay,
          },
          on: {
            input: function ($event) {
              return _vm.onFromPickerInput($event)
            },
          },
        },
        [
          _c("div", { staticClass: "mt-date-range-picker__selection-info" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("mt.components.date-range-input.from-date", {
                    date: _vm.sDateRangeModel[0],
                  })
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "v-date-picker",
        {
          staticClass: "mt-date-range-picker__date-picker",
          attrs: {
            range: "",
            "no-title": "",
            disabled: _vm.disabled,
            locale: _vm.locale,
            value: _vm.toDateRange,
            min: _vm.sDateRangeModel[0],
            max: _vm.maxDate,
            dayFormat: _vm.formatDay,
          },
          on: {
            input: function ($event) {
              return _vm.onToPickerInput($event)
            },
          },
        },
        [
          _c("div", { staticClass: "mt-date-range-picker__selection-info" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("mt.components.date-range-input.to-date", {
                    date: _vm.sDateRangeModel[1],
                  })
                ) +
                " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }