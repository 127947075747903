import { provide, ref, inject, InjectionKey, Ref } from 'vue';

const injectionKey = Symbol('sidebarState') as InjectionKey<Ref<boolean | null>>;

export function provideSidebarState(state: Ref<boolean | null>): void {
  return provide(injectionKey, state);
}

/**
 * Composition function that returns Ref<boolean | null>
 */
function useSidebarState(): Ref<boolean | null> {
  return inject(injectionKey, ref(null));
}

export default useSidebarState;
