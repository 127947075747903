
import { defineComponent } from 'vue';
import { MtOverlay } from '@/components/MtOverlay';
import { MtCancelButton, MtDeleteButton } from '@/components/MtButton';
import { useEmitter } from '@/utils/useEmitter';
import { MtConfirmDeleteOverlayEvents } from './MtConfirmDeleteOverlay';

export default defineComponent({
  components: { MtOverlay, MtCancelButton, MtDeleteButton },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    noSelected: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const emit = useEmitter<MtConfirmDeleteOverlayEvents>();

    function confirm(): void {
      emit('confirm', {});
    }

    function cancel(): void {
      emit('cancel', {});
    }

    return {
      confirm,
      cancel,
    };
  },
});
