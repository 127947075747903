
import { defineComponent, ref, watch } from 'vue';
import { Location } from 'vue-router/types/router';
import { Route } from 'vue-router';
import useRouter from '@/hooks/useRouter';
import useRoute from '@/hooks/useRoute';

type SidebarMenuItem = {
  icon: string;
  name: string;
  route: Location;
};

export default defineComponent({
  setup() {
    const router = useRouter();
    const route$ = useRoute();

    const items$ = ref<Array<SidebarMenuItem>>([
      {
        icon: 'fas fa-users',
        name: 'mt.sidebar.file-manager',
        route: { name: 'File Manager' },
      },
      {
        icon: 'fas fa-globe',
        name: 'mt.sidebar.kym',
        route: { name: 'Kym' },
      },
    ]);

    const item$ = ref<number | undefined>();

    function syncCurrentRoute(route: Route) {
      const itemIndex: number = items$.value.findIndex((item) =>
        route.matched.some((route) => route.name === item.route.name)
      );
      item$.value = itemIndex !== -1 ? itemIndex : undefined;
    }
    watch(route$, (route) => {
      syncCurrentRoute(route);
    });
    syncCurrentRoute(router.currentRoute);

    function navigate(item: SidebarMenuItem) {
      if (item.route.name === router.currentRoute.name) return;

      router.push({
        ...item.route,
        params: router.currentRoute.params,
      } as Location);
    }

    watch(item$, (i) => {
      if (i === undefined) return;

      navigate(items$.value[i]);
    });

    return {
      item$,
      items$,
    };
  },
});
