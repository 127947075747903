import { Ref, ref, provide, inject, InjectionKey } from 'vue';
import { RouterMetadata, unit } from '@/models/RouterMetadata';

const injectionKey = Symbol('routerMetadata') as InjectionKey<Ref<RouterMetadata>>;

export function provideRouterMetadata(config: Ref<RouterMetadata>): void {
  return provide(injectionKey, config);
}

/**
 * Composition function that returns Ref<RouterMetadata>
 */
function useRouterMetadata(): Ref<RouterMetadata> {
  return inject(injectionKey, ref<RouterMetadata>(unit));
}

export default useRouterMetadata;
