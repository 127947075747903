import { provide, inject, InjectionKey, ref, Ref } from 'vue';
import { AsyncState } from '@/models/AsyncState';

const injectionKey = Symbol('appSetupState') as InjectionKey<Ref<AsyncState<void>>>;

export function provideAppSetupState(payload: Ref<AsyncState<void>>): void {
  return provide(injectionKey, payload);
}

/**
 * Composition function that returns AsyncState for app setup
 */
function useAppSetupState(): Ref<AsyncState<void>> {
  return inject(injectionKey, ref<AsyncState<void>>({ status: 'idle' as const }));
}

export default useAppSetupState;
