
import { defineComponent } from 'vue';
import MtLanguageMenu from '@/components/MtLanguageMenu/MtLanguageMenu.vue';
import useSidebarState from '@/hooks/useSidebarState';
import useAuthService from '@/hooks/useAuthService';

export default defineComponent({
  components: { MtLanguageMenu },
  setup() {
    const sidebar = useSidebarState();
    const authService = useAuthService();

    async function logout() {
      await authService.logout();
    }

    return {
      sidebar,
      logout,
    };
  },
});
