import { IdObject } from '@/models/IdObject';

export interface FinancialInstitutionsService {
  get(locale?: string): Promise<Array<IdObject>>;
}

export interface InstitutionNamesResponse {
  [fid: string]:
    | {
        display_name: string;
        is_test_fi?: boolean;
      }
    | undefined;
}

export const unit: FinancialInstitutionsService = {
  get: () => Promise.resolve([]),
};
