import { provide, inject, InjectionKey } from 'vue';
import VueRouter from 'vue-router';

const injectionKey = Symbol('router') as InjectionKey<VueRouter>;

export function provideRouter(router: VueRouter): void {
  return provide(injectionKey, router);
}

/**
 * Composition function that returns an instance of VueRouter
 * Remove once vue-router supports 2.7 https://github.com/vuejs/vue-router/issues/3760
 */
function useRouter(): VueRouter {
  return inject(injectionKey, new VueRouter());
}

export default useRouter;
