
import { defineComponent, ref, watch, onBeforeMount, onUnmounted, Ref, PropType } from 'vue';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import * as RouterMetadata from '@/models/RouterMetadata';
import { Locale } from '@/models/Locale';
import { Logger } from '@/services/logger';
import { Config } from '@/configs/app';
import { I18nMessageProvider } from '@/services/I18nMessageProvider';
import { AuthService } from '@/services/auth/AuthService';
import { MessageService } from '@/components/MtMessages/MessageService';
import { FileManagerService } from '@/services/FileManagerService';
import { KymAppReportService } from '@/services/kym/KymAppReportService';
import { FinancialInstitutionsService } from '@/services/FinancialInstitutionsService';
import { mergeLocaleMessages } from '@/i18n';
import { provideI18n } from '@/hooks/useI18n';
import { provideI18nMessageProvider } from '@/hooks/useI18nMessageProvider';
import { provideAppConfig } from '@/hooks/useAppConfig';
import { provideAuthService } from '@/hooks/useAuthService';
import { provideMessageService } from '@/hooks/useMessageService';
import { provideFileManagerService } from '@/hooks/useFileManagerService';
import { provideKymAppReportService } from '@/hooks/useKymAppReportService';
import { provideInstitutionsService } from '@/hooks/useInstitutionsService';
import { provideRouter } from '@/hooks/useRouter';
import { provideLogger } from '@/hooks/useLogger';
import { provideLocale } from '@/hooks/useLocale';
import { provideRouterMetadata } from '@/hooks/useRouterMetadata';
import { provideAppSetupState } from '@/hooks/useAppSetupState';
import { useIntervalAuthCheck } from './useIntervalAuthCheck';
import useAsyncRequest from '@/hooks/useAsyncRequest';

export default defineComponent({
  name: 'App',
  props: {
    i18n: {
      type: Object as PropType<VueI18n>,
      required: true,
    },
    i18nMessageProvider: {
      type: Object as PropType<I18nMessageProvider>,
      required: true,
    },
    appConfig: {
      type: Object as PropType<Config>,
      required: true,
    },
    authService: {
      type: Object as PropType<AuthService>,
      required: true,
    },
    messageService: {
      type: Object as PropType<MessageService>,
      required: true,
    },
    fileManagerService: {
      type: Object as PropType<FileManagerService>,
      required: true,
    },
    kymAppReportService: {
      type: Object as PropType<KymAppReportService>,
      required: true,
    },
    institutionsService: {
      type: Object as PropType<FinancialInstitutionsService>,
      required: true,
    },
    router: {
      type: Object as PropType<VueRouter>,
      required: true,
    },
    logger: {
      type: Function as PropType<Logger>,
      required: true,
    },
    locale: {
      // refs must be passed as callback functions :/
      type: Function as PropType<() => Ref<Locale>>,
      required: true,
    },
  },
  setup(props) {
    const routerMetadata = ref(RouterMetadata.unit);
    const setup = useAsyncRequest(async () => {
      mergeLocaleMessages(props.i18n, await props.i18nMessageProvider.get(props.i18n.availableLocales));
    });

    onBeforeMount(() => {
      setup.run();
    });
    onUnmounted(
      props.router.afterEach((currentRoute, previousRoute) => {
        routerMetadata.value = { currentRoute, previousRoute };
      }) as () => void
    );
    watch(setup.state, (state) => {
      if (state.status === 'failure') {
        props.logger({ message: state.result, level: 'error' });
      }
    });

    useIntervalAuthCheck();
    provideI18n(props.i18n);
    provideI18nMessageProvider(props.i18nMessageProvider);
    provideAppConfig(props.appConfig);
    provideAuthService(props.authService);
    provideMessageService(props.messageService);
    provideFileManagerService(props.fileManagerService);
    provideKymAppReportService(props.kymAppReportService);
    provideInstitutionsService(props.institutionsService);
    provideRouter(props.router);
    provideLogger(props.logger);
    provideLocale(props.locale());
    provideRouterMetadata(routerMetadata);
    provideAppSetupState(setup.state);
  },
});
