// External Imports
import { ref, Ref } from 'vue';
// Project Imports
import useMessageService from '@/hooks/useMessageService';
import useFileManagerService from '@/hooks/useFileManagerService';
// Local Imports
import { ExportItem } from '../classes/ExportItem';

interface UsePreparedQuery {
  exportLoading: Ref<boolean>;
  requestExport: (queryParams?: Record<string, string>) => Promise<ExportItem | null>;
}

export function usePreparedQuery(preparedQueryId: string): UsePreparedQuery {
  const messageService = useMessageService();
  const fileManagerService = useFileManagerService();

  const exportLoading = ref<boolean>(false);

  async function requestExport(queryParams?: Record<string, string>): Promise<ExportItem | null> {
    exportLoading.value = true;
    let result: ExportItem | null = null;
    try {
      const queryOptions = { id: preparedQueryId, queryParams };

      const exportMetadata = await fileManagerService.executePreparedQuery(queryOptions);

      messageService.queueMessage({
        type: 'success',
        text: 'mt.views.file-manager.export-started',
      });

      result = new ExportItem(exportMetadata);
    } catch (e) {
      messageService.queueMessage({
        type: 'error',
        text: 'mt.views.file-manager.export-failed',
        secondaryText: 'mt.views.file-manager.unexpected-error',
      });
    }

    exportLoading.value = false;
    return result;
  }

  return { exportLoading, requestExport };
}

export default usePreparedQuery;
