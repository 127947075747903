
import { computed, defineComponent, ref, toRefs } from 'vue';
import { MtGraphCardProps } from './MtGraphCard';
import MtDoughnutGraph from '@/views/kym/components/MtDoughnutGraph.vue';
import useTranslate from '@/hooks/useTranslate';
import { DataPoint } from '@/views/kym/components/MtDoughnutGraph';

export default defineComponent({
  components: { MtDoughnutGraph },
  props: {
    ascending: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: false,
    },
    filteredLabels: {
      type: Array,
      default: () => {
        return [];
      },
    },
    labelTitle: {
      type: String,
    },
    limit: {
      type: Number,
      default: 7,
    },
    percentageTitle: {
      type: String,
      default: 'mt.views.kym.tables.percentage',
    },
    title: {
      type: String,
    },
    valueFormatter: {
      type: Intl.NumberFormat,
      default: () => {
        return new Intl.NumberFormat('ja-JP', { style: 'decimal' });
      },
    },
    valueTitle: {
      type: String,
    },
  },
  setup(props: MtGraphCardProps) {
    const t = useTranslate();

    const { ascending: ascending$, data: data$, limit: limit$, filteredLabels: filteredLabels$ } = toRefs(props);
    const percentageFormatter$ = ref<Intl.NumberFormat>(new Intl.NumberFormat('ja-JP', { style: 'percent' }));

    const showOthers$ = ref<boolean>(true);

    const sortedData$ = computed<Array<DataPoint> | undefined>(() => {
      if (!data$.value) return;

      return [...data$.value].sort((a, b) => {
        const orderSign = ascending$.value ? -1 : 1;
        return orderSign * (b.value - a.value);
      });
    });

    const filteredPoint$ = computed<DataPoint | null>(() => {
      if (!sortedData$.value || filteredLabels$.value.length === 0) return null;

      const filteredPoints = sortedData$.value.filter((point) => filteredLabels$.value.includes(point.id));

      return {
        id: 'mt.views.kym.tables.hidden',
        name: 'mt.views.kym.tables.hidden',
        value: filteredPoints.map((point) => point.value).reduce((sum, value) => sum + value, 0),
      };
    });

    const filteredData$ = computed<Array<DataPoint> | undefined>(() => {
      if (!sortedData$.value) return;

      return filteredLabels$.value.length > 0
        ? sortedData$.value.filter((point) => !filteredLabels$.value.includes(point.id))
        : sortedData$.value;
    });

    const limitedData$ = computed<Array<DataPoint> | undefined>(() => {
      if (!filteredData$.value) {
        return;
      } else if (filteredData$.value.length <= limit$.value + 1) {
        return filteredData$.value;
      } else {
        return filteredData$.value.slice(0, limit$.value);
      }
    });
    const others$ = computed<DataPoint | null>(() => {
      if (!filteredData$.value || filteredData$.value.length <= limit$.value + 1) {
        return null;
      } else {
        return {
          id: 'mt.views.kym.tables.others',
          name: 'mt.views.kym.tables.others',
          value: filteredData$.value
            .slice(limit$.value)
            .map((point) => point.value)
            .reduce((sum, value) => sum + value, 0),
        };
      }
    });

    const graphData$ = computed<Array<DataPoint> | undefined>(() => {
      if (!limitedData$.value) {
        return;
      } else if (showOthers$.value && others$.value !== null) {
        return [...limitedData$.value, others$.value];
      } else {
        return limitedData$.value;
      }
    });

    const total$ = computed<number | undefined>(() =>
      sortedData$.value ? sortedData$.value.reduce((sum, point) => sum + point.value, 0) : undefined
    );

    function calculatePercentage(value: number, total: number): number {
      return Math.abs(value / total);
    }

    function formatPercentage(value: number): string {
      if (isNaN(value)) return '-';

      return percentageFormatter$.value.format(value);
    }

    const percentages$ = computed<Array<string> | undefined>(() => {
      if (limitedData$.value) {
        return limitedData$.value
          .map((point) => point.value)
          .map((value) => calculatePercentage(value, total$.value ?? 0))
          .map(formatPercentage);
      } else {
        return undefined;
      }
    });

    const othersPercentage$ = computed<string | null>(() => {
      if (others$.value && total$.value) {
        const percentage = calculatePercentage(others$.value.value, total$.value);
        return formatPercentage(percentage);
      } else {
        return null;
      }
    });

    const filteredPercentage$ = computed<string | null>(() => {
      if (filteredPoint$.value && total$.value) {
        const percentage = calculatePercentage(filteredPoint$.value.value, total$.value);
        return formatPercentage(percentage);
      } else {
        return null;
      }
    });

    const colorPalette$ = ref<Array<string>>([
      // Based on color palette defined in Figma
      '#298C3D',
      '#E67700',
      '#088287',
      '#CC3A27',
      '#3A4147',
    ]);
    const backgroundColors$ = computed<Array<string>>(() => {
      const palette = colorPalette$.value;
      const colors = [];
      let paletteIndex = 0;
      for (let i = 0; i <= limit$.value; i++) {
        if (paletteIndex >= palette.length) paletteIndex = 0;

        colors.push(palette[paletteIndex]);
        paletteIndex++;
      }

      if (colors[0] === colors[colors.length - 1]) {
        colors[colors.length - 1] = colors[1];
      } else if (colors[0] === colors[colors.length - 2]) {
        colors[colors.length - 1] = colors[1];
        colors[colors.length - 2] = colors[2];
      }

      return colors;
    });

    return {
      t,

      backgroundColors$,
      graphData$,
      filteredPoint$,
      filteredPercentage$,
      percentages$,
      percentageFormatter$,
      limitedData$,
      others$,
      othersPercentage$,
      showOthers$,
      sortedData$,
      total$,
    };
  },
});
