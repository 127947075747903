import { I18nMessageProvider } from '@/services/I18nMessageProvider';
import { FinancialInstitutionsService } from '@/services/FinancialInstitutionsService';
import { Locale, LocaleMessageObject, LocaleMessages } from 'vue-i18n';

type Config = {
  institutionsService: FinancialInstitutionsService;
};

export class InstitutionNamesProvider implements I18nMessageProvider {
  protected _institutionsService: FinancialInstitutionsService;

  constructor(config: Config) {
    this._institutionsService = config.institutionsService;
  }

  async get(locales: Array<Locale>): Promise<LocaleMessages> {
    const result: LocaleMessages = {};

    const addOneLocale = async (locale: Locale) => {
      const institutions = await this._institutionsService.get(locale);

      const messageObject = {} as LocaleMessageObject;

      for (const { id, name } of institutions) {
        if (name) {
          messageObject[id] = name;
        }
      }

      result[locale] = {
        mt: {
          'institution-names': messageObject,
        },
      };
    };

    // map each locale to a promise (the value returned by the async function above),
    // so we can await their completion
    await Promise.all(locales.map(addOneLocale));

    return result;
  }
}
