
// External Imports
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { VCol, VContainer, VProgressCircular, VRow, VSheet } from 'vuetify/lib';
// Project Imports
import MtButton from '@/components/MtButton/MtButton.vue';
import useTranslate from '@/hooks/useTranslate';
// Local Imports
import MtTransactionExportModal from './components/MtTransactionExportModal.vue';
import MtFileManagerTable from './components/MtFileManagerTable.vue';
import useExportList from './components/useExportList';
import usePreparedQuery from './components/usePreparedQuery';

export default defineComponent({
  components: {
    MtButton,
    MtTransactionExportModal,
    MtFileManagerTable,
    VCol,
    VContainer,
    VProgressCircular,
    VRow,
    VSheet,
  },
  setup() {
    const t = useTranslate();

    const {
      exportList,
      exportDownloadExpiresInMs,
      isExportListLoaded,
      isDeletingExports,
      addRequestedExport,
      startPollingExportList,
      stopPollingExportList,
      deleteExports,
    } = useExportList();

    const guestsQuery = usePreparedQuery('filemanagerGuests');
    const accountsQuery = usePreparedQuery('filemanagerAccounts');
    const anyLoading = computed(
      () => guestsQuery.exportLoading.value || accountsQuery.exportLoading.value || !isExportListLoaded.value
    );

    const isTransactionsModalOpen = ref<boolean>(false);

    function showTransactionsModal(): void {
      isTransactionsModalOpen.value = true;
    }

    function closeTransactionsModal(): void {
      isTransactionsModalOpen.value = false;
    }

    async function requestGuestsFile(): Promise<void> {
      const requestedExport = await guestsQuery.requestExport();
      if (requestedExport) {
        addRequestedExport(requestedExport);
      }
    }

    async function requestAccountsFile(): Promise<void> {
      const requestedExport = await accountsQuery.requestExport();
      if (requestedExport) {
        addRequestedExport(requestedExport);
      }
    }

    onBeforeMount(() => {
      startPollingExportList();
    });

    onBeforeUnmount(() => {
      stopPollingExportList();
    });

    return {
      t,
      showTransactionsModal,
      closeTransactionsModal,
      requestGuestsFile,
      requestAccountsFile,
      addRequestedExport,
      deleteExports,
      isExportListLoaded,
      isDeletingExports,
      guestsExportLoading: guestsQuery.exportLoading,
      accountsExportLoading: accountsQuery.exportLoading,
      anyLoading,
      isTransactionsModalOpen,
      exportList,
      exportDownloadExpiresInMs,
    };
  },
});
