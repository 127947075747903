import { Locale, LocaleMessages } from 'vue-i18n';

export interface I18nMessageProvider {
  get(locales: Array<Locale>): Promise<LocaleMessages>;
}

export const unit: I18nMessageProvider = {
  get: () => Promise.resolve({}),
};

export const merge = (...providers: ReadonlyArray<I18nMessageProvider>): I18nMessageProvider => {
  return {
    get: async (locales) => {
      const results = await Promise.all(providers.map((fn) => fn.get(locales)));
      return results.reduce(
        (acc, result) =>
          Object.entries(result).reduce(
            (acc_, [locale, messageObject]) => ({
              ...acc_,
              [locale]: {
                ...acc_[locale],
                ...messageObject,
              },
            }),
            acc
          ),
        {} as LocaleMessages
      );
    },
  };
};
