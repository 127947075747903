import { ZodType } from 'zod';
import { Dictionary } from 'vue-router/types/router';
import useRouter from '@/hooks/useRouter';

type toRecordReducer<KEY extends string | number, VALUE> = (
  map: Record<KEY, VALUE>,
  entry: [KEY, VALUE]
) => Record<KEY, VALUE>;

function _toRecord<KEY extends string | number, VALUE>(
  map: Record<KEY, VALUE>,
  [key, value]: [KEY, VALUE]
): Record<KEY, VALUE> {
  map[key] = value;
  return map;
}

function toRecord<KEY extends string | number, VALUE>(): [toRecordReducer<KEY, VALUE>, Record<KEY, VALUE>] {
  return [_toRecord, {} as Record<KEY, VALUE>];
}

/*
 * TODO: Refactor to use withPersistedState with a Storage backed by the URL query instead
 *   2021-03-26 by: @maragon
 */
// For composition functions it is better to have implicit return types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withUrlState() {
  const router = useRouter();

  function updateUrl(state: Record<string, unknown | undefined> | null): void {
    let query: Dictionary<string | (string | null)[]> | undefined;
    if (state !== null) {
      const serializedState = Object.entries(state)
        .map<[string, undefined | string]>(([key, value]) => {
          if (!value || (Array.isArray(value) && !value.length)) {
            return [key, undefined];
          } else {
            return [key, JSON.stringify(value)];
          }
        })
        .reduce(...toRecord<string, undefined | string>());

      query = {
        ...router.currentRoute.query,
        ...(serializedState as Dictionary<string | (string | null)[]>),
      };
    }

    if (router.currentRoute.name === null) {
      console.log('Unexpected null route name.');
      return;
    }

    // Nothing to do
    // noinspection JSIgnoredPromiseFromCall
    router.push({
      name: router.currentRoute.name,
      params: router.currentRoute.params,
      query,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getInitialState<KEY extends string>(state: Record<KEY, ZodType<unknown>>): Record<KEY, any> {
    return Object.entries(state)
      .map<[string, unknown]>(([key, shape]) => {
        if (!(key in router.currentRoute.query)) return [key, undefined];

        const serializedValue = router.currentRoute.query[key];
        if (typeof serializedValue !== 'string') {
          console.warn(`Couldn't parse '${key}' stored in route's query`);
          // Reset url
          updateUrl({ [key]: undefined });
          return [key, undefined];
        }

        let value;
        try {
          value = JSON.parse(serializedValue);
        } catch (e) {
          console.warn(`Couldn't parse '${key}' stored in route's query`, e);
          // Reset url
          updateUrl({ [key]: undefined });
          return [key, undefined];
        }

        const parseResult = (shape as ZodType<unknown>).safeParse(value);
        if (!parseResult.success) {
          console.warn(`'${key}' present in url is invalid`, parseResult.error.toString());
          // Reset url
          updateUrl({ [key]: undefined });
          return [key, undefined];
        }

        return [key, parseResult.data];
      })
      .reduce(...toRecord());
  }

  return {
    getInitialState,
    updateUrl,
  };
}
