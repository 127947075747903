import { NavigationGuard } from 'vue-router';
import { RouterHookConfig } from '@/router/index';
import { AuthService } from '@/services/auth/AuthService';

export type Config = {
  authService: AuthService;
};

export function authenticatedGuard({ authService }: Config): RouterHookConfig {
  const guard: NavigationGuard = async (to, from, next) => {
    console.log('Auth guard: checking...');

    if (await authService.isAuthenticated()) {
      console.log('Auth guard: authenticated!');
      next();
    } else {
      console.log('Auth guard: not authenticated, starting authentication flow');
      authService.initiateAuthentication();
    }
  };

  return {
    stage: 'beforeEach',
    hook: guard,
  };
}
