export interface AuthService {
  isAuthenticated(): Promise<boolean>;

  initiateAuthentication(): void;

  ensureAuthentication(): Promise<void>;

  logout(): Promise<void>;

  getToken(): Promise<string>;
}

export const unit: AuthService = {
  isAuthenticated: async () => false,
  initiateAuthentication: () => null,
  ensureAuthentication: async () => undefined,
  logout: async () => undefined,
  getToken: async () => '',
};
