import { ExportMetadata, ExportStatus } from '@/services/FileManagerService';

export class ExportItem {
  date: number; // timestamp
  filesize?: number | null;
  filename: string;
  queryId: string;
  status: ExportStatus;
  deleteable: boolean;

  constructor(exportMetadata: ExportMetadata) {
    this.date = exportMetadata.requestedAt;
    this.filesize = exportMetadata.filesize;
    this.filename = exportMetadata.userFilename || exportMetadata.systemFilename || '';
    this.queryId = exportMetadata.queryId;
    this.status = exportMetadata.status;
    // Deleting items which are not 'DONE' or 'FAILED' is more complex and currently not supported.
    this.deleteable = exportMetadata.status === 'DONE' || exportMetadata.status === 'FAILED';
  }
}

// Should be in sync with expiration logic in lambdas/filemanager/download.py
export const isExpired = (
  item: ExportItem,
  downloadExpiresInMs: undefined | number,
  relativeTimeMs: number
): boolean => {
  return downloadExpiresInMs !== undefined && relativeTimeMs > item.date + downloadExpiresInMs;
};

export const isDownloadable = (
  item: ExportItem,
  downloadExpiresInMs: undefined | number,
  relativeTimeMs: number
): boolean => {
  return item.status === 'DONE' && !isExpired(item, downloadExpiresInMs, relativeTimeMs);
};

export default ExportItem;
