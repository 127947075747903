import { Ref, computed } from 'vue';
import { Route } from 'vue-router';
import useRouterMetadata from '@/hooks/useRouterMetadata';

/**
 * Remove once vue-router supports 2.7 https://github.com/vuejs/vue-router/issues/3760
 **/
function useRoute(): Ref<Route> {
  const routerMetadata = useRouterMetadata();
  return computed(() => routerMetadata.value.currentRoute);
}

export default useRoute;
