import { provide, inject, InjectionKey } from 'vue';
import * as FinancialInstitutionsService from '@/services/FinancialInstitutionsService';

const injectionKey = Symbol(
  'institutionsService'
) as InjectionKey<FinancialInstitutionsService.FinancialInstitutionsService>;

export function provideInstitutionsService(
  institutionsService: FinancialInstitutionsService.FinancialInstitutionsService
): void {
  return provide(injectionKey, institutionsService);
}

/**
 * Composition function that returns FinancialInstitutionsService
 */
function useInstitutionsService(): FinancialInstitutionsService.FinancialInstitutionsService {
  return inject(injectionKey, FinancialInstitutionsService.unit);
}

export default useInstitutionsService;
