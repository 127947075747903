// External imports
import { ref, Ref } from 'vue';
import { useStorage } from '@vueuse/core';
// Project Imports
import useMessageService from '@/hooks/useMessageService';
import useFileManagerService from '@/hooks/useFileManagerService';
import { GetExportMetadataResponse } from '@/services/FileManagerService';
import { ExportItem } from '../classes/ExportItem';

const LONG_POLL_DELAY_MS = 60_000;
const MIN_BACK_OFF_POLL_DELAY_MS = 1_000;
const MAX_BACK_OFF_POLL_DELAY_MS = 15_000;
const POLL_BACK_OFF_MULTIPLIER = 0.05;
// In most cases it should only take about 2 seconds for the export to appear in getExportQueries responses.
// In realistic usage another user won't see and delete a file you created so rapidly, so setting this a little higher than necessary should be safe.
const MIN_AGE_FOR_DELETED_MS = 8_000;

export interface UseExportList {
  exportList: Ref<ExportItem[]>;
  exportDownloadExpiresInMs: Ref<number | undefined>;
  isExportListLoaded: Ref<boolean>;
  isDeletingExports: Ref<boolean>;
  addRequestedExport: (requestedExport: ExportItem) => void;
  startPollingExportList: () => void;
  stopPollingExportList: () => void;
  deleteExports: (queryIds: string[]) => Promise<void>;
}

export function useExportList(): UseExportList {
  const fileManagerService = useFileManagerService();
  const messageService = useMessageService();

  // Contains the list of metadata for all exports we are aware of.
  // 'DONE' and 'FAILED' query metadata should never change so we cache the data in storage to avoid unnecessary requests.
  const exportList: Ref<ExportItem[]> = useStorage('useExportList-exportList', []);
  const exportDownloadExpiresInMs = ref<number | undefined>();
  // This boolean only refers to the initial load. Further refresh loads happen silently.
  const isExportListLoaded = ref<boolean>(false);
  const isDeletingExports = ref<boolean>(false);

  let pollingTimeoutId: ReturnType<typeof setTimeout> | null = null;
  let pollingStartTimestamp = 0;
  let lastPollTimestamp = 0;

  function addRequestedExport(requestedExport: ExportItem) {
    exportList.value = [...exportList.value, requestedExport];
    startPollingExportList();
  }

  async function getMetadataForIds(queryIds: string[]): Promise<GetExportMetadataResponse[]> {
    const queryPromises: Array<Promise<GetExportMetadataResponse>> = [];
    for (const queryId of queryIds) {
      queryPromises.push(fileManagerService.getExportMetadata(queryId));
    }
    return Promise.all(queryPromises);
  }

  function isExportDeleted(exportItem: ExportItem, queryIds: Set<string>) {
    // If the export item is not in the list of queryIds we assume that it has been deleted.
    if (!queryIds.has(exportItem.queryId)) {
      // There is a chance that an export we just created is not visible in the getExportQueries response yet.
      // Possibly because the metadata file might not be created yet.
      // To avoid race conditions with newly created exports only treat them as deleted if they are older than the minimum age.
      const exportAge = Date.now() - exportItem.date;
      return exportAge > MIN_AGE_FOR_DELETED_MS;
    }
    // We see the export item in the list of query ids, so it has not been deleted.
    return false;
  }

  async function loadExportList() {
    try {
      // Get the current export query ids from the api.
      const queries = await fileManagerService.getExportQueries();
      const queryIds = new Set(queries.queryIds);

      exportDownloadExpiresInMs.value = queries.fileDownloadExpiresInMs ?? undefined;
      // Remove deleted exports based on queryIds seen in the response.
      const filteredExportList = exportList.value.filter((exportItem) => !isExportDeleted(exportItem, queryIds));

      // Get an object mapping export query ids to metadata for easier processing.
      const exports: Record<string, ExportItem> = {};
      for (const exp of filteredExportList) {
        exports[exp.queryId] = exp;
      }

      // Get the latest metadata for any export which is not complete or missing metadata.
      const queryIdsToQuery: string[] = [];
      for (const queryId of queryIds) {
        if (!exports[queryId] || ['INPROGRESS', 'QUEUED'].includes(exports[queryId].status)) {
          queryIdsToQuery.push(queryId);
        }
      }
      const fetchedMetadata = await getMetadataForIds(queryIdsToQuery);

      // Merge the fetched Metadata into the exports object
      for (const exp of fetchedMetadata) {
        exports[exp.queryId] = new ExportItem(exp);
      }

      // Finally save the updated metadata into the exportList
      exportList.value = Object.values(exports);
    } catch (e) {
      messageService.queueMessage({
        type: 'error',
        text: 'mt.views.file-manager.export-list.get-export-list-failed',
        secondaryText: 'mt.views.file-manager.unexpected-error',
      });
    }
    isExportListLoaded.value = true;
  }

  // Removes the specified exports from the local list of exports.
  // Can be used to reflect a delete without reloading from the API
  function removeFromExportList(queryIds: string[]) {
    const removeQueryIds = new Set(queryIds);
    exportList.value = exportList.value.filter(({ queryId }) => !removeQueryIds.has(queryId));
  }

  function getPollDelayMs() {
    let pollDelayMs = LONG_POLL_DELAY_MS;
    const hasUnfinished = exportList.value.some((exportItem) => ['QUEUED', 'INPROGRESS'].includes(exportItem.status));
    if (hasUnfinished) {
      // If there are unfinished exports poll more frequently with a gradual back-off.
      const timeSinceStart = Date.now() - pollingStartTimestamp;
      // Get the poll delay based on how much time has passed since polling started.
      pollDelayMs = timeSinceStart * POLL_BACK_OFF_MULTIPLIER;
      // Make sure the poll delay isn't lower than the minimum, or greater than the maximum.
      pollDelayMs = Math.max(pollDelayMs, MIN_BACK_OFF_POLL_DELAY_MS);
      pollDelayMs = Math.min(pollDelayMs, MAX_BACK_OFF_POLL_DELAY_MS);
    }
    // Else nothing is in progress, so a long delay is acceptable.

    return pollDelayMs;
  }

  async function poll() {
    const thisPollTimestamp = Date.now();
    lastPollTimestamp = thisPollTimestamp;
    // Note: It's possible that poll() could be called again during this await. So be careful of race conditions.
    await loadExportList();

    if (thisPollTimestamp === lastPollTimestamp) {
      const pollDelayMs = getPollDelayMs();
      pollingTimeoutId = setTimeout(poll, pollDelayMs);
    }
    // Else: The current lastPollTimestamp is not from this execution chain, so stop polling.
  }

  function stopPollingExportList() {
    if (pollingTimeoutId) {
      clearTimeout(pollingTimeoutId);
      pollingTimeoutId = null;
    }
    pollingStartTimestamp = 0;
    lastPollTimestamp = 0;
  }

  function startPollingExportList() {
    stopPollingExportList();
    pollingStartTimestamp = Date.now();
    poll();
  }

  async function deleteExports(queryIds: string[]) {
    // Stop polling while a delete is in progress to keep the state predictable.
    stopPollingExportList();
    isDeletingExports.value = true;
    try {
      const { deleted, missing } = await fileManagerService.deleteExports(queryIds);
      messageService.queueMessage({
        type: 'success',
        text: ['mt.views.file-manager.export-list.delete-success', { noDeleted: deleted.length }],
      });
      removeFromExportList(deleted);
      removeFromExportList(missing);
    } catch (e) {
      messageService.queueMessage({
        type: 'error',
        text: ['mt.views.file-manager.export-list.delete-failure', { noAttempted: queryIds.length }],
        secondaryText: 'mt.views.file-manager.unexpected-error',
      });
    } finally {
      isDeletingExports.value = false;
      startPollingExportList();
    }
  }

  return {
    exportList,
    exportDownloadExpiresInMs,
    isExportListLoaded,
    isDeletingExports,
    addRequestedExport,
    startPollingExportList,
    stopPollingExportList,
    deleteExports,
  };
}

export default useExportList;
